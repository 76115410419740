import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { APIS } from "../../utils/axiosConf";

const initialState = {
  token: null,
  loading: false,
  user: null,
  error: null,
  changeSucessfully: null,
  isOTPVerified: false,
  isPasswordReset: false,
  userInfo: null,
  OTPError: null,
  reseetPasswordValidData: null,
};

// --------------- login -----------------
export const LoginUser = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.login,
        data: data,
        isFormData: true,
      });
      console.log("login", res?.data);
      return res.data;
    } catch (error) {
      console.log("login Error: " + JSON.stringify(error?.response?.data));
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- register -----------------
export const Register = createAsyncThunk(
  "auth/register",
  async (data, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.register,
        data: data,
        isFormData: true,
      });
      console.log("register", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data?.errors?.email?.[0] ||
        error.response?.data?.errors?.phone?.[0] ||
        "An unexpected error occurred";
      console.log("register Error: " + errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// --------------- GET User Details -----------------
export const getUserDetails = createAsyncThunk(
  "auth/getUserDetails",
  async (id, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: `${APIS.userDetails}/`,
        accessToken: token,
        pathParams: id,
      });
      console.log(`getUserDetails success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`getUserDetails Error: `, error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- Update profile -----------------
export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.updateProfile,
        data: data,
        accessToken: token,
        isFormData: true,
      });
      console.log("updateProfile ", res?.data);
      return res.data;
    } catch (error) {
      const errorMessage = error?.response?.data;
      const message = errorMessage[Object.keys(errorMessage)[0]][0];
      console.log("updateProfile Error: " + JSON.stringify(message));
      return rejectWithValue(message);
    }
  }
);

// --------------- Change password -----------------
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.changePassword,
        data: data,
        accessToken: token,
        isFormData: true,
      });
      console.log("changePassword ", res?.data);
      return res.data;
    } catch (error) {
      console.log(
        "changePassword Error: " + JSON.stringify(error?.response?.data)
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- Forget password -----------------
export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.forgetPassword,
        data: data,
        isFormData: true,
      });
      console.log("forgetPassword ", res?.data);
      return res.data;
    } catch (error) {
      console.log(
        "forgetPassword Error: " + JSON.stringify(error?.response?.data)
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- OTP verification -----------------
export const verifyOTP = createAsyncThunk(
  "auth/verifyOTP",
  async (otpData, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.validateOtp,
        data: otpData,
        isFormData: true,
      });
      console.log("verifyOTP ", res?.data);
      return res.data;
    } catch (error) {
      console.log("verifyOTP Error: " + JSON.stringify(error?.response?.data));
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- submitting phone number ----------
export const submitOTPNumber = createAsyncThunk(
  "auth/submitOTPNumber",
  async (OTPNumber, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.submitOTPNumber,
        data: OTPNumber,
        isFormData: true,
      });
      console.log("submitOTPNumber ", res?.data);
      return res.data;
    } catch (error) {
      console.log(
        "submitOTPNumber Error: " + JSON.stringify(error?.response?.data)
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- Verify OTP number ----------
export const verifyOTPWithNumber = createAsyncThunk(
  "auth/verifyOTPWithNumber",
  async (OTPAndPhone, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.verifyOTPWithNumber,
        data: OTPAndPhone,
        isFormData: true,
      });
      console.log("verifyOTPWithNumber ", res?.data);
      return res.data;
    } catch (error) {
      console.log(
        "verifyOTPWithNumber Error: " + JSON.stringify(error?.response?.data)
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- Resend OTP Number ----------
export const resendOTPNumber = createAsyncThunk(
  "auth/resendOTPNumber",
  async (phoneNumber, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.resendOTPNumber,
        data: phoneNumber,
        isFormData: true,
      });
      console.log("resendOTPNumber ", res?.data);
      return res.data;
    } catch (error) {
      console.log(
        "resendOTPNumber Error: " + JSON.stringify(error?.response?.data)
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- reset password -----------------
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.resetPassword,
        data: data,
        isFormData: true,
      });
      console.log("resetPassword ", res?.data);
      return res.data;
    } catch (error) {
      console.log(
        "resetPassword Error: " + JSON.stringify(error?.response?.data)
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    // Define logoutSuccess to reset the auth state
    logoutSuccess: (state) => {
      state.token = null;
      state.user = null;
      state.userInfo = null;
      state.isOTPVerified = false;
      state.isPasswordReset = false;
      state.error = null;
    },
    sendReseetPasswordValidData: (state, action) => {
      state.reseetPasswordValidData = action.payload
    }
  },
  extraReducers: (builder) => {
    // --------------- Login-----------------
    builder.addCase(LoginUser.pending, (state, action) => {
      state.loading = true;
      state.user = null;
      state.token = null;
    });
    builder.addCase(LoginUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.error = null;
    });
    builder.addCase(LoginUser.rejected, (state, action) => {
      state.loading = false;
    });
    // --------------- Register-----------------
    builder.addCase(Register.pending, (state, action) => {
      state.loading = true;
      state.user = null;
      state.token = null;
      state.error = null;
    });
    builder.addCase(Register.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.error = null;
    });
    builder.addCase(Register.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- get User Details-----------------
    builder.addCase(getUserDetails.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
      state.error = null;
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- Update Profile-----------------
    builder.addCase(updateProfile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.error = null;
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- Change password -----------------
    builder.addCase(changePassword.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.changeSucessfully = "Changed Sucessfully!";
      state.error = null;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // --------------- Forget password -----------------
    builder.addCase(forgetPassword.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(forgetPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.error = null;
    });
    builder.addCase(forgetPassword.rejected, (state, action) => {
      state.loading = false;
    });
    // --------------- OTP verification  -----------------
    builder.addCase(verifyOTP.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyOTP.fulfilled, (state) => {
      state.loading = false;
      state.isOTPVerified = true;
      state.error = null;
    });
    builder.addCase(verifyOTP.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- submitting phone number  -----------------
    builder.addCase(submitOTPNumber.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(submitOTPNumber.fulfilled, (state) => {
      state.loading = false;
      state.isOTPVerified = true; // Or handle accordingly
      state.error = null;
      state.OTPError = null;
    });
    builder.addCase(submitOTPNumber.rejected, (state, action) => {
      state.loading = false;
      state.OTPError = action.payload.message;
    });
    // --------------- Verify OTP With number  -----------------
    builder.addCase(verifyOTPWithNumber.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyOTPWithNumber.fulfilled, (state) => {
      state.loading = false;
      state.isOTPVerified = true; // Or handle accordingly
      state.error = null;
      state.OTPError = null;
    });
    builder.addCase(verifyOTPWithNumber.rejected, (state, action) => {
      state.loading = false;
      state.OTPError = action.payload.message;
    });
    // --------------- reset password -----------------
    builder.addCase(resetPassword.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.error = null;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { logoutSuccess, sendReseetPasswordValidData } = authSlice.actions;
export default authSlice.reducer;

// validateOtp
// submitPhoneNumber
