import React from "react";
import { Link } from "react-router-dom";
import btnStyles from './styles.module.css';
import Loading from "../Loading";

function Button({ title, onClick, to, style, loading }) {
    return (
        <>
            {to ? (
                <Link
                    to={!loading && to}
                    onClick={loading ? null : onClick}
                    className={`${btnStyles.btn} ${style} ${loading ? btnStyles.loading : ''}`}
                >
                    {loading ? <Loading /> : title}
                </Link>
            ) : (
                <button
                    onClick={loading ? null : onClick}
                    className={`${btnStyles.btn} ${style} ${loading ? btnStyles.loading : ''}`}
                    disabled={loading}
                >
                    {loading ? <Loading /> : title}
                </button>
            )}
        </>
    );
}

export default Button;
