import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { APIS } from "../../utils/axiosConf";

// --------------- sendContactMessage ----------
export const sendContactMessage = createAsyncThunk(
  "auth/sendContactMessage",
  async (data, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.contactApi,
        data: data,
        isFormData: true,
      });
      console.log("sendContactMessage success: ", res?.data);
      return res.data;
    } catch (error) {
      console.log(
        "sendContactMessage Error: " + JSON.stringify(error?.response?.data)
      );
      return rejectWithValue(error);
    }
  }
);

const contactUs = createSlice({
  name: "contact",
  initialState: { loading: false, error: null },
  extraReducers: (builder) => {
    builder
      .addCase(sendContactMessage.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendContactMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(sendContactMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default contactUs.reducer;
