import axios from "axios";

// live
const BASE_URL = 'https://trejars.com/backend/public/api/';
// staging
// const BASE_URL = "https://trejars.com/backend_staging/public/api/";

export const APIS = {
  // =================== auth =================== //
  login: "login",
  register: "register",
  updateProfile: "updateProfile",
  changePassword: "changePassword",
  forgetPassword: "forget-password",
  userDetails: "userDetails",
  validateOtp: "validate-otp",
  submitOTPNumber: "verify-phone",
  resendOTPNumber: "resend-otp",
  verifyOTPWithNumber: "verify-otp",
  resetPassword: "reset-password",
  // =================== products =================== //
  allProducts: "products/all",
  productDetails: "products/getProduct/",
  goldPrice: "products/getGoldPrice",
  goldTypes: "products/getGoldTypes",
  getMargin: "products/margin",
  addToCart: "cart/add",
  showCart: "cart",
  makePayment: "payments",
  wallet: "wallet",
  purchaseWallet: "purchase",
  delPet: "cart/",
  OrderDetails: "order-details",
  productCount: "cart/update",
  uploadImages: "upload-image",
  search: "search",
  filter: "filterProducts",
  filterCategories: "categories/all",
  filterData: "type",
  // =================== contact =================== //
  contactApi: "contact-us",
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    let err = error?.response?.data || "Bad Request";
    console.log("Request Error ::", err);
    return Promise.reject(error);
  }
);

const Axios = async ({
  method,
  path,
  data,
  params,
  header = {},
  pathParams = "",
  isFormData = false,
  accessToken = "",
}) => {
  const authHeder = accessToken
    ? {
        Authorization: `Bearer ${accessToken}`,
      }
    : {};

  const response = await axiosInstance.request({
    method: method,
    url: `${path}${pathParams}`,
    data: data,
    params: params,
    headers: {
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      Accept: "application/json",
      ...header,
      ...authHeder,
    },
  });
  return response;
};

export default Axios;
