import React, { useEffect, useState } from "react";
import checkStyles from "./styles.module.css";
import {
  addBtn,
  BankTrans,
  checkout,
  mastercard,
  meeza,
  shipCar,
  visa,
  Wallets,
} from "../../assets";
import { Button } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CreatePayment,
  purchaseWallet,
} from "../../redux/slices/productsSlice";
import Modal from "react-modal";
import Dropdown from "react-dropdown";
import { EGYPTCITIES } from "../../utils/data";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserDetails } from "../../redux/slices/authSlice";

function Checkout() {
  const [isChecked, setIsChecked] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [bankPeriod, setBankPeriod] = useState("");
  const [isPaymentClicked, setIsPaymentClicked] = useState(false);
  const [bankModalIsOpen, setBankModalIsOpen] = useState(false);
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const [shippingFees, setShippingFees] = useState(0);
  const [selectedTempShipping, setSelectedTempShipping] = useState("");
  const [selectedShipping, setSelectedShipping] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [originalPrice, setOriginalPrice] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(null);
  const [transferData, setTransferData] = useState({
    deposited_price: null,
    receipt_image: null,
  });
  const workmanship = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, paymentData, cartData } = useSelector((state) => state.products);
  const { userInfo, user } = useSelector((state) => state.auth);
  const [totalFees, setTotalFees] = useState(0);

  useEffect(() => {
    if (userInfo && userInfo.wallets) {
      setTotalFees(userInfo?.wallets[0]?.wallet_total)
    }
  }, [userInfo]);

  const queryParams = new URLSearchParams(location.search);
  const paymentId = queryParams.get("payment_id");
  const success = queryParams.get("success");

  useEffect(() => {
    if (success !== null && paymentId != null) {
      if (success == 1) {
        navigate("/orderConfirm", { state: { paymentId: paymentId } });
      }
    }
  }, [success, paymentId, navigate]);

  useEffect(() => {
    const totalSum =
      selectedShipping === "to_address"
        ? subtotal + shippingFees + workmanship
        : selectedPayment
          ? subtotal + workmanship
          : originalPrice + workmanship;

    setTotal(totalSum);
  }, [
    originalPrice,
    selectedPayment,
    selectedShipping,
    shippingFees,
    subtotal,
  ]);

  useEffect(() => {
    const cleanedTotalPrice = Number(cartData?.total_price?.replace(/,/g, ""));
    setOriginalPrice(cleanedTotalPrice);
    const total = cartData?.cart_details?.reduce(
      (sum, item) => sum + Number(item?.total_price?.replace(/,/g, "")),
      0
    );

    setOriginalPrice(total);
  }, [cartData]);

  // =========================== Start of Submit Order Handler ===========================
  const handleSubmitOrder = () => {
    if (!selectedPayment) {
      notify("You must select payment method");
    } else if (selectedPayment === "credit" && !bankPeriod) {
      notify("You must select installment period");
    } else if (selectedPayment === "Wallet" && total >= totalFees) {
      notify("Please Add More Money In Your Balance");
    } else if (
      (selectedPayment === "Instapay" || selectedPayment === "Bank Transfer") &&
      (!transferData?.deposited_price || !transferData?.receipt_image)
    ) {
      notify("You must add deposited price and image");
    } else if (!selectedShipping) {
      notify("You must select shipping method");
    } else if (selectedShipping === "to_address" && !selectedCity) {
      notify("You must select shipping city");
    } else {
      let delivery = selectedShipping === 'in_store' ? 'STORE DELIVERY' : `${selectedCity}`
      const data = {
        delivery_price: total - originalPrice,
        delivary_method: delivery
      }
      if (selectedPayment === "Wallet") {
        dispatch(purchaseWallet(data))
          .unwrap()
          .then(() => {
            notify("successful wallet payment process")
            setTimeout(() => {
              navigate("/goldPrice");
            }, 1000);
          }).catch(err => {
            notify("error wallet payment process")
          })
      } else {
        dispatch(CreatePayment(data))
          .unwrap()
          .then(() => {
            setIsPaymentClicked(true);
          }).catch(err => {
            notify("error payment process")
          })
      }
    }
  };

  useEffect(() => {
    if (!loading && paymentData && isPaymentClicked) {
      window.location.href = paymentData.redirect_url;
    }
  }, [isPaymentClicked, loading, paymentData]);

  const Cities = EGYPTCITIES.map((city) => city.name);

  const modal_style = {
    content: {
      width: "40%",
      height: addressModalIsOpen && "70%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#1D1D1D",
      padding: "2% 4%",
    },
  };

  const CheckPaymentMethod = () => {
    switch (selectedPayment) {
      case "aman":
        setSubtotal(originalPrice + originalPrice * 0.05);
        break;
      case "debit":
        setIsChecked(false);
        setSubtotal(originalPrice + originalPrice * 0.025 + 4);
        break;
      case "Wallet":
        setIsChecked(true);
        setSubtotal(originalPrice);
        break;
      case "Meeza":
        setSubtotal(originalPrice + originalPrice * 0.015 + 2.5);
        break;
      case "credit":
        switch (bankPeriod) {
          case "6":
            setSubtotal(originalPrice + originalPrice * 0.09);
            break;
          case "9":
            setSubtotal(originalPrice + originalPrice * 0.13);
            break;
          case "10":
            setSubtotal(originalPrice + originalPrice * 0.14);
            break;
          case "12":
            setSubtotal(originalPrice + originalPrice * 0.16);
            break;
          case "18":
            setSubtotal(originalPrice + originalPrice * 0.21);
            break;
          case "24":
            setSubtotal(originalPrice + originalPrice * 0.27);
            break;
          case "30":
            setSubtotal(originalPrice + originalPrice * 0.31);
            break;
          case "36":
            setSubtotal(originalPrice + originalPrice * 0.37);
            break;
          default:
            setSubtotal(originalPrice);
            setIsChecked(false);
            break;
        }
        break;
      default:
        setSubtotal(originalPrice);
        setIsChecked(false); // Default case to reset the check
        break;
    }
  };

  useEffect(() => {
    CheckPaymentMethod();
    setTransferData({ deposited_price: null, receipt_image: null });
  }, [selectedPayment, bankPeriod]);

  const handleShippingFees = () => {
    const city = EGYPTCITIES.find((city) => city.name === selectedCity);
    subtotal > 3000000
      ? setShippingFees(city.fees * 4)
      : subtotal > 2000000
        ? setShippingFees(city.fees * 3)
        : subtotal > 1000000
          ? setShippingFees(city.fees * 2)
          : setShippingFees(city.fees);
  };

  useEffect(() => {
    selectedCity !== "" && handleShippingFees();
  }, [selectedCity]);

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setTransferData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // const handleFileInputChange = (event) => {
  //   const { name } = event.target;
  //   const value = event.target.files[0];

  //   setTransferData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const notify = (text) => {
    toast(
      <div className={`${checkStyles.Toastify__container} d-flex flex-column`}>
        <p className={checkStyles.toast_message}>{text}</p>
      </div>,
      {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: true,
        rtl: false,
        theme: "dark",
        className: `${checkStyles.Toastify__toast} pt-3 pb-0`,
        closeButton: false,
      }
    );
  };

  useEffect(() => {
    dispatch(getUserDetails(user && user.id));
  }, [dispatch]);

  return (
    <main>
      <section className="container">
        <div className={"d-flex flex-column align-items-center my-4 py-3"}>
          <div className={checkStyles.img_box}>
            <img src={checkout} alt="checkout cart" className="img" />
          </div>
          <p className={`${checkStyles.checkout_title} mt-3`}>
            check <span>out</span>
          </p>
        </div>
        <div className={`gray_box px-3 py-3`}>
          <div className="d-flex justify-content-between pe-md-5">
            <p className={checkStyles.total_order}>
              total order <span>in</span> cart
            </p>
            <p className={`${checkStyles.total_items} pe-4 pe-md-5 me-md-5`}>
              {cartData?.cart_details?.length} items
            </p>
          </div>
          <div className="horizontal_line mt-2 mb-4" />
          <div className="d-flex justify-content-between pe-md-5">
            <p className={`${checkStyles.total_order} mb-0`}>total price</p>
            <p className={`${checkStyles.total_price} pe-md-4 me-md-5 mb-0`}>
              {cartData?.cart_details
                ?.reduce(
                  (sum, product) =>
                    sum + Number(product?.total_price?.replace(/,/g, "")),
                  0
                )
                .toFixed(2)}
              egp
            </p>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row my-4">
          {/* <div
            className={`${checkStyles.gray_container} d-flex col justify-content-between align-items-center me-md-4 px-4 py-3 mb-4 mb-md-0`}
          >
            <div className="d-flex align-items-center">
              <div className={checkStyles.img_box}>
                <img src={bank} alt="bank logo" className="img" />
              </div>
              <div className="ms-2">
                <p className={`${checkStyles.stor_title} mb-1`}>
                  store securely
                </p>
                <p className={`${checkStyles.stor_txt} mb-0`}>
                  annual storage cost
                </p>
              </div>
            </div>
            <p className={`${checkStyles.total_price} mb-0`}>7,300 egp</p>
          </div> */}
          <div
            className={`${checkStyles.gray_container} d-flex col justify-content-between align-items-center px-4 py-3`}
          >
            <div className="d-flex align-items-center">
              <div className={checkStyles.img_box}>
                <img src={shipCar} alt="big-car logo" className="img" />
              </div>
              <div className="ms-2">
                <p className={`${checkStyles.stor_title} mb-1`}>
                  ship <span>to</span> my address
                </p>
                <p className={`${checkStyles.stor_txt} mb-0`}>
                  select your shipping address{" "}
                </p>
              </div>
            </div>
            <div
              className={checkStyles.plus_img_box}
              onClick={() => setAddressModalIsOpen(true)}
            >
              <img src={addBtn} alt="add logo" className="img" />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row">
          <div
            className={`${checkStyles.gray_container} col justify-content-between me-md-4 flex-grow-1 px-4 py-3 mb-4 mb-md-0`}
          >
            <p className={`${checkStyles.checkout_title} mb-3`}>payment</p>
            <p className={`${checkStyles.payment_txt}`}>
              Please select your payment method
            </p>
            <div className="horizontal_line" />
            <div className="mt-5 mb-3">
              {/* <div className="d-flex align-items-start justify-content-between mb-3">
                <div>
                  <p className={`${checkStyles.payment_title} mb-1`}>aman</p>
                  <p className={`${checkStyles.payment_txt} mb-0`}>
                    Fees Apply
                  </p>
                </div>
                <div className={checkStyles.plus_img_box}>
                  <img
                    src={
                      "https://www.amanshops.com/PDFs/UsersProfileImages/Aman_Logo.png"
                    }
                    alt="valu logo"
                    className="img"
                  />
                </div>
                <input
                  type="radio"
                  id="aman"
                  className={checkStyles.radio_input}
                  name="method"
                  value="aman"
                  checked={selectedPayment === "aman"}
                  onChange={(e) => setSelectedPayment(e.target.value)}
                />
                <label htmlFor="aman" className={checkStyles.radio_btn} />
              </div> */}
              <div className="d-flex align-items-start justify-content-between mb-3">
                <div>
                  <p className={`${checkStyles.payment_title} mb-1`}>
                    debit cards
                  </p>
                  <p className={`${checkStyles.payment_txt} mb-0`}>
                    Fees Apply
                  </p>
                </div>
                <div className="d-flex">
                  <img src={visa} alt="visa logo" className="me-2" />
                  <img
                    src={mastercard}
                    alt="mastercard logo"
                    className="me-2"
                  />
                </div>
                <input
                  type="radio"
                  id="debit"
                  className={checkStyles.radio_input}
                  name="method"
                  value="debit"
                  checked={selectedPayment === "debit"}
                  onChange={(e) => setSelectedPayment(e.target.value)}
                />
                <label htmlFor="debit" className={checkStyles.radio_btn} />
              </div>
              {/* <div className="d-flex align-items-start justify-content-between mb-3">
                <div>
                  <p className={`${checkStyles.payment_title} mb-1`}>
                    El Ahli Cards Installments
                  </p>
                  <p className={`${checkStyles.payment_txt} mb-0`}>
                    Fees Apply
                  </p>
                </div>
                <div className={checkStyles.plus_img_box}>
                  <img
                    src={
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/NBE_logo.png/800px-NBE_logo.png"
                    }
                    alt="bank account logo"
                    className="img"
                  />
                </div>
                <input
                  type="radio"
                  id="bank_transfer"
                  className={checkStyles.radio_input}
                  name="method"
                  value="credit"
                  checked={selectedPayment === "credit"}
                  onChange={(e) => {
                    setSelectedPayment(e.target.value);
                    setBankModalIsOpen(true);
                  }}
                />
                <label
                  htmlFor="bank_transfer"
                  className={checkStyles.radio_btn}
                />
              </div>
              <div className="d-flex align-items-start justify-content-between mb-3">
                <div>
                  <p className={`${checkStyles.payment_title} mb-1`}>Meeza</p>
                  <p className={`${checkStyles.payment_txt} mb-0`}>
                    Fees Apply
                  </p>
                </div>
                <div className={checkStyles.meeza_img_box}>
                  <img src={meeza} alt="Meeza logo" className="img" />
                </div>
                <input
                  type="radio"
                  id="Meeza"
                  className={checkStyles.radio_input}
                  name="method"
                  value="Meeza"
                  checked={selectedPayment === "Meeza"}
                  onChange={(e) => setSelectedPayment(e.target.value)}
                />
                <label htmlFor="Meeza" className={checkStyles.radio_btn} />
              </div> */}
              <div className={checkStyles.wallet_container}>
                <div className="d-flex align-items-start justify-content-between mb-3">
                  <div>
                    <p className={`${checkStyles.payment_title} mb-1`}>
                      Wallet
                    </p>
                  </div>
                  <div className={` gap-2 ${checkStyles.meeza_img_box}`}>
                    <img src={BankTrans} alt="Wallet logo" className="img" />
                  </div>
                  <input
                    type="radio"
                    id="Wallet"
                    className={checkStyles.radio_input}
                    name="method"
                    value="Wallet"
                    checked={selectedPayment === "Wallet"}
                    onChange={(e) => setSelectedPayment(e.target.value)}
                  />
                  <label htmlFor="Wallet" className={checkStyles.radio_btn} />
                </div>
                <div
                  className={` pt-3 ${checkStyles.wallet_info} ${isChecked ? checkStyles.active : ""
                    } `}
                >
                  <p className={`mb-0  ${checkStyles.checkout_balance_title}`}>
                    Your current Balance: {totalFees} EGP
                  </p>
                  <Button
                    title={"Add money"}
                    to={"/goldPrice"}
                    style={`px-2 py-1 ${checkStyles.checkout_balance_btn}`}
                  />
                </div>
              </div>
              {/*<div className="d-flex align-items-start justify-content-between mb-3">
                <div>
                  <p className={`${checkStyles.payment_title} mb-1`}>
                    Instapay
                  </p>
                  <p className={`${checkStyles.payment_txt} mb-0`}>No Fees</p>
                </div>
                <div className={checkStyles.plus_img_box}>
                  <img
                    src={
                      "https://play-lh.googleusercontent.com/Pv1p8v-KJq4Z_LLOjQjBZpK8DNJg4Zb5aNOQY7WfoiHR4Gfs165Z1TTNMThZ883Yfg"
                    }
                    alt="Instapay logo"
                    className="img"
                  />
                </div>
                <input
                  type="radio"
                  id="Instapay"
                  className={checkStyles.radio_input}
                  name="method"
                  value="Instapay"
                  checked={selectedPayment === "Instapay"}
                  onChange={(e) => {
                    setSelectedPayment(e.target.value);
                    setInstapayModalIsOpen(true);
                  }}
                />
                <label htmlFor="Instapay" className={checkStyles.radio_btn} />
              </div> */}
              {/*<div className="d-flex align-items-start justify-content-between">
                <div>
                  <p className={`${checkStyles.payment_title} mb-1`}>
                    Bank Transfer
                  </p>
                  <p className={`${checkStyles.payment_txt} mb-0`}>No Fees</p>
                </div>
                <div className={checkStyles.plus_img_box}>
                  <img
                    src={
                      "https://c8.alamy.com/comp/2BWR0D2/bank-transfer-icon-simple-element-from-banking-collection-creative-bank-transfer-icon-for-web-design-templates-infographics-and-more-2BWR0D2.jpg"
                    }
                    alt="Bank Transfer logo"
                    className="img"
                  />
                </div>
                <input
                  type="radio"
                  id="Bank Transfer"
                  className={checkStyles.radio_input}
                  name="method"
                  value="Bank Transfer"
                  checked={selectedPayment === "Bank Transfer"}
                  onChange={(e) => {
                    setSelectedPayment(e.target.value);
                    setInstapayModalIsOpen(true);
                  }}
                />
                <label
                  htmlFor="Bank Transfer"
                  className={checkStyles.radio_btn}
                />
              </div> */}
            </div>
          </div>
          <div
            className={`${checkStyles.gray_container} col justify-content-between flex-grow-1 px-4 py-3`}
          >
            <p className={`${checkStyles.checkout_title} mb-5`}>
              order summary
            </p>
            <div className="horizontal_line" />
            <div className="me-md-5 my-5">
              {selectedShipping === "to_address" && (
                <div className="d-flex justify-content-between">
                  <p className={`${checkStyles.summary_title}`}>shipping</p>
                  <p className={`${checkStyles.summary_price}`}>
                    {shippingFees} egp
                  </p>
                </div>
              )}
              <div className="d-flex justify-content-between">
                <p className={`${checkStyles.summary_title}`}>subtotal</p>
                <p className={`${checkStyles.summary_price}`}>
                  {originalPrice?.toFixed(2)} egp
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className={`${checkStyles.summary_title}`}>Workmanship</p>
                <p className={`${checkStyles.summary_price}`}>
                  {workmanship?.toFixed(2)} egp
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className={`${checkStyles.summary_title}`}>total</p>
                <p className={`${checkStyles.summary_price}`}>
                  {total?.toFixed(2)} egp
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <Button
                title={"place order"}
                onClick={handleSubmitOrder}
                style={`w-50 py-1`}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </section>
      {/* bank transfer period modal */}
      <Modal
        isOpen={bankModalIsOpen}
        onRequestClose={() => setBankModalIsOpen(false)}
        style={modal_style}
        contentLabel="bank modal"
      >
        <h5 className={`${checkStyles.modal_title} mb-4`}>
          El Ahly bank installment periods
        </h5>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>6 months</p>
          <input
            type="radio"
            id="6"
            className={checkStyles.period_radio_input}
            name="period"
            value="6"
            checked={bankPeriod === "6"}
            onChange={(e) => setBankPeriod(e.target.value)}
          />
          <label htmlFor="6" className={checkStyles.period_radio_btn} />
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>9 months</p>
          <input
            type="radio"
            id="9"
            className={checkStyles.period_radio_input}
            name="period"
            value="9"
            checked={bankPeriod === "9"}
            onChange={(e) => setBankPeriod(e.target.value)}
          />
          <label htmlFor="9" className={checkStyles.period_radio_btn} />
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>10 months</p>
          <input
            type="radio"
            id="10"
            className={checkStyles.period_radio_input}
            name="period"
            value="10"
            checked={bankPeriod === "10"}
            onChange={(e) => setBankPeriod(e.target.value)}
          />
          <label htmlFor="10" className={checkStyles.period_radio_btn} />
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>12 months</p>
          <input
            type="radio"
            id="12"
            className={checkStyles.period_radio_input}
            name="period"
            value="12"
            checked={bankPeriod === "12"}
            onChange={(e) => setBankPeriod(e.target.value)}
          />
          <label htmlFor="12" className={checkStyles.period_radio_btn} />
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>18 months</p>
          <input
            type="radio"
            id="18"
            className={checkStyles.period_radio_input}
            name="period"
            value="18"
            checked={bankPeriod === "18"}
            onChange={(e) => setBankPeriod(e.target.value)}
          />
          <label htmlFor="18" className={checkStyles.period_radio_btn} />
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>24 months</p>
          <input
            type="radio"
            id="24"
            className={checkStyles.period_radio_input}
            name="period"
            value="24"
            checked={bankPeriod === "24"}
            onChange={(e) => setBankPeriod(e.target.value)}
          />
          <label htmlFor="24" className={checkStyles.period_radio_btn} />
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>30 months</p>
          <input
            type="radio"
            id="30"
            className={checkStyles.period_radio_input}
            name="period"
            value="30"
            checked={bankPeriod === "30"}
            onChange={(e) => setBankPeriod(e.target.value)}
          />
          <label htmlFor="30" className={checkStyles.period_radio_btn} />
        </div>
        <div className="d-flex align-items-start justify-content-between mb-5">
          <p className={`${checkStyles.payment_title} mb-0`}>36 months</p>
          <input
            type="radio"
            id="36"
            className={checkStyles.period_radio_input}
            name="period"
            value="36"
            checked={bankPeriod === "36"}
            onChange={(e) => setBankPeriod(e.target.value)}
          />
          <label htmlFor="36" className={checkStyles.period_radio_btn} />
        </div>
        <div className="d-flex justify-content-center">
          <Button
            title={"done"}
            onClick={() => bankPeriod && setBankModalIsOpen(false)}
            style={`w-50 py-1`}
          />
        </div>
      </Modal>

      {/* address modal */}
      <Modal
        isOpen={addressModalIsOpen}
        style={modal_style}
        contentLabel="bank modal"
      >
        <h5 className={`${checkStyles.modal_title} mb-4`}>shipping method</h5>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <div>
            <p className={`${checkStyles.payment_title} mb-0`}>
              IN STORE DELIVERY
            </p>
            <p className={`${checkStyles.payment_txt} mb-0`}>
              Come and pick your products in store.
            </p>
          </div>
          <input
            type="radio"
            id="in_store"
            className={checkStyles.period_radio_input}
            name="shipping"
            value="in_store"
            checked={selectedTempShipping === "in_store"}
            onChange={(e) => setSelectedTempShipping(e.target.value)}
          />
          <label htmlFor="in_store" className={checkStyles.period_radio_btn} />
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <div>
            <p className={`${checkStyles.payment_title} mb-0`}>
              TREJAR SECURED DELIVERY
            </p>
            <p className={`${checkStyles.payment_txt} mb-0`}>
              Secured delivery to your doorstep.
            </p>
          </div>
          <input
            type="radio"
            id="to_address"
            className={checkStyles.period_radio_input}
            name="shipping"
            value="to_address"
            checked={selectedTempShipping === "to_address"}
            onChange={(e) => setSelectedTempShipping(e.target.value)}
          />
          <label
            htmlFor="to_address"
            className={checkStyles.period_radio_btn}
          />
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>City</p>
          <Dropdown
            options={Cities}
            onChange={(value) => setSelectedCity(value.value)}
            value={selectedCity}
            placeholder="Select your city"
            className={checkStyles.cities_dropdown}
            menuClassName={checkStyles.cities_content}
            placeholderClassName={checkStyles.cities_placeholder}
          />
        </div>
        <div className="d-flex justify-content-center">
          <Button
            title={"OK"}
            style={checkStyles.shipping_btn}
            onClick={() => {
              setSelectedShipping(selectedTempShipping)
              setAddressModalIsOpen(false)
            }}
            loading={loading}
          />
        </div>
      </Modal>
      {/* Instapay and bank transfer modal */}
      {/*<Modal
        isOpen={instapayModalIsOpen}
        onRequestClose={() => setInstapayModalIsOpen(false)}
        style={modal_style}
        contentLabel="Instapay modal"
      >
        <h5 className={`${checkStyles.modal_title} mb-4`}>{selectedPayment}</h5>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>name</p>
          <p className={`${checkStyles.payment_txt} mb-0`}>
            Trejars For Gold & Precious Metals Trading
          </p>
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>address</p>
          <p className={`${checkStyles.payment_txt} mb-0 w-75`}>
            Unit 3, Building 7, Second floor, Lejanda village, Second district
          </p>
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>bank name</p>
          <p className={`${checkStyles.payment_txt} mb-0`}>Egypt Bank</p>
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>account number</p>
          <p className={`${checkStyles.payment_txt} mb-0`}>5120001000017578</p>
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>IBAN </p>
          <p className={`${checkStyles.payment_txt} mb-0`}>
            EG190002051205120001000017578
          </p>
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>SWIFT CODE</p>
          <p className={`${checkStyles.payment_txt} mb-0`}>BMISEGCXXXX</p>
        </div>
        <div className="horizontal_line" />
        <div className="d-flex align-items-start justify-content-between mt-3 mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>
            Deposited amount
          </p>
          <InputField
            type={"text"}
            fieldName={"deposited_price"}
            placeholder={"Enter deposited price"}
            style={checkStyles.field_box}
            inputStyle={checkStyles.input}
            value={transferData.deposited_price}
            onChange={handleInputChange}
          />
        </div>
        <div className="d-flex align-items-start justify-content-between mb-0">
          <p className={`${checkStyles.payment_title} mb-0`}>Receipt image</p>
          <InputField
            type={"file"}
            fieldName={"receipt_image"}
            placeholder={"no image selected"}
            value={transferData.receipt_image?.name}
            onChange={handleFileInputChange}
          />
        </div>
        {selectedPayment === "Bank Transfer" && (
          <p className={`${checkStyles.working_days} mb-0 mb-4`}>
            Wait 1 working day after recieving money to confirm deposit.
          </p>
        )}
        <div className="d-flex justify-content-center">
          <Button
            title={"done"}
            onClick={() =>
              transferData?.deposited_price &&
              transferData?.receipt_image &&
              setInstapayModalIsOpen(false)
            }
            style={`w-50 py-1`}
          />
        </div>
      </Modal> */}
      <ToastContainer />
    </main>
  );
}

export default Checkout;
