import { ColorRing } from 'react-loader-spinner';

function Loading() {
  return (
    <ColorRing
      visible={true}
      height="25"
      width="35"
      ariaLabel="color-ring-loading"
      wrapperStyle={{}}
      wrapperClass="color-ring-wrapper"
      colors={['#000']}
    />
  );
}

export default Loading;