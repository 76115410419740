import React, { useState } from "react";
import {
  apple,
  checkBox,
  checkedBox,
  eye,
  eyeSlash,
  facebookCircle,
  google,
} from "../../assets";
import { Button, InputField, SendOtp } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { Register, submitOTPNumber } from "../../redux/slices/authSlice";
import { Link, useNavigate } from "react-router-dom";
import loginStyles from "../Login/styles.module.css";
import styles from "./styles.module.css";

function SignUp() {
  const [isTermsChecked, setisTermsChecked] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [errormessage, setErrormessage] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    address: "",
  });
  const { loading, OTPError } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setErrormessage("");
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateData = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const passwordStrengthRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*><]{8,128}$/;
    const phoneNumberRegex = /^(010|011|012|015)(\d{8})?$|^(010|011|012|015)$/;
    if (
      !formData?.firstName?.trim() ||
      !formData?.lastName?.trim() ||
      !formData?.email?.trim() ||
      !formData?.password?.trim() ||
      !formData?.confirmPassword?.trim() ||
      !formData?.phone?.trim() ||
      !formData?.address?.trim()
    ) {
      setErrormessage("All fields must be filled.");
      return false;
    }

    if (!emailRegex?.test(formData.email)) {
      setErrormessage("Invalid email format.");
      return false;
    }

    if (!passwordStrengthRegex?.test(formData?.password)) {
      setErrormessage(
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long."
      );
      return false;
    }

    if (formData?.password !== formData?.confirmPassword) {
      setErrormessage("Passwords do not match.");
      return false;
    }

    if (!phoneNumberRegex?.test(formData?.phone)) {
      setErrormessage("Invalid phone number format.");
      return false;
    }

    if (!isTermsChecked) {
      setErrormessage("terms and conditions must be agreed.");
      return false;
    }

    setErrormessage("");
    return true;
  };

  const confirmRegister = () => {
    if (!validateData()) return;
    const data = {
      Fname: formData.firstName,
      Lname: formData.lastName,
      email: formData.email,
      password: formData.password,
      password_confirmation: formData.confirmPassword,
      phone: `+2${formData.phone}`,
      address: formData.address,
    };

    dispatch(Register(data))
      .unwrap()
      .then(() => {
        setShowOtp(true);
      })
      .catch((err) => {
        console.error("Registration error:", err);
        setErrormessage(err);
      });
  };

  const handleOtpSubmit = (enteredOtp) => {
    const OTPNumber = {
      verification_code: String(enteredOtp),
      phone: `+2${formData.phone}`,
    };

    if (showOtp) {
      dispatch(submitOTPNumber(OTPNumber))
        .unwrap()
        .then((response) => {
          console.log("Success response from server:", response);
          navigate("/login");
        })
        .catch((error) => {
          console.error("Error during OTP submission:", error);
          console.error("Full error object:", error);
          // setErrormessage(error?.message || "OTP submission failed.");
        });
    }
  };

  return (
    <main>
      <div className="container pt-5">
        <div className="row justify-content-center">
          <div
            className={`${loginStyles.login_content} col-11 col-md-7 col-lg-5 ps-4 pe-3 pb-3`}
          >
            <p className={loginStyles.login_txt}>sign up</p>
            <p className={loginStyles.welcome_txt}>TREJARS</p>
            <div className="d-flex justify-content-between mb-3">
              <InputField
                type={"text"}
                style={`${loginStyles.half_input}`}
                inputStyle={loginStyles.input}
                label={"first name *"}
                placeholder={"Enter your first name"}
                fieldName={"firstName"}
                value={formData?.firstName}
                onChange={handleInputChange}
              />
              <InputField
                type={"text"}
                style={`${loginStyles.half_input}`}
                inputStyle={loginStyles.input}
                label={"last name *"}
                placeholder={"Enter your last name"}
                fieldName={"lastName"}
                value={formData?.lastName}
                onChange={handleInputChange}
              />
            </div>
            <InputField
              type={"text"}
              fieldName={"email"}
              label={"email *"}
              placeholder={"Enter Your Email"}
              style={loginStyles.field_box}
              inputStyle={loginStyles.input}
              value={formData.email}
              onChange={handleInputChange}
            />
            <InputField
              type={!isPasswordVisible ? "password" : "text"}
              fieldName={"password"}
              label={"set password *"}
              placeholder={"Enter Your Password"}
              style={loginStyles.field_box}
              inputStyle={loginStyles.input}
              value={formData.password}
              onChange={handleInputChange}
              rightIcon={
                isPasswordVisible ? (
                  <img src={eye} alt="eye" className="img" />
                ) : (
                  <img src={eyeSlash} alt="eye-slash" className="img" />
                )
              }
              onRightIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
            />
            <InputField
              type={!isConfirmPasswordVisible ? "password" : "text"}
              fieldName={"confirmPassword"}
              label={"confirm password *"}
              placeholder={"Enter confirm Password"}
              style={loginStyles.field_box}
              inputStyle={loginStyles.input}
              value={formData.confirmPassword}
              onChange={handleInputChange}
              rightIcon={
                isConfirmPasswordVisible ? (
                  <img src={eye} alt="eye" className="img" />
                ) : (
                  <img src={eyeSlash} alt="eye-slash" className="img" />
                )
              }
              onRightIconClick={() =>
                setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
              }
            />
            <div className={`${styles.phone_code_parent}`}>
              <p className={`${styles.phone_code} ${loginStyles.rem_txt}`}>
                +2
              </p>
              <InputField
                type={"text"}
                fieldName={"phone"}
                label={"phone *"}
                placeholder={"Enter Your Phone"}
                style={loginStyles.field_box}
                inputStyle={`${loginStyles.input} ${styles.phone_input}`}
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            <InputField
              type={"text"}
              fieldName={"address"}
              label={"address *"}
              placeholder={"Enter Your Address"}
              style={loginStyles.field_box}
              inputStyle={loginStyles.input}
              value={formData.address}
              onChange={handleInputChange}
            />
            <div
              className="d-flex align-items-center c-pointer mb-3"
              onClick={() => setisTermsChecked(!isTermsChecked)}
            >
              {isTermsChecked ? (
                <img src={checkedBox} alt="checked box" />
              ) : (
                <img src={checkBox} alt="check box" />
              )}
              <Link className={`${loginStyles.rem_txt} mb-0 ms-2`} to={'/privacyPolicy'}>Agree to Terms and Conditions.</Link>
            </div>
            {errormessage && (
              <p
                className={`${loginStyles.rem_txt} ${loginStyles.errorText} ms-2 mb-3`}
              >
                {errormessage}
              </p>
            )}
            {showOtp && (
              <div>
                <SendOtp
                  phone={`+2${formData.phone}`}
                  navigation={"/login"}
                  error={OTPError}
                  onOtpComplete={(enteredOtp) => {
                    handleOtpSubmit(enteredOtp);
                  }}
                />
              </div>
            )}
            <Button
              title={"sign up"}
              style={loginStyles.login_btn}
              onClick={confirmRegister}
              loading={loading}
            />
            <p className={loginStyles.login_with_txt}>or sign up with</p>
            <div className="d-flex justify-content-center mb-3">
              <div className={loginStyles.icon_box}>
                <img src={apple} alt="apple" className="img cover" />
              </div>
              <div className={loginStyles.icon_box}>
                <img src={google} alt="gmail" className="img cover" />
              </div>
              <div className={loginStyles.icon_box}>
                <img
                  src={facebookCircle}
                  alt="facebook"
                  className="img cover"
                />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default SignUp;