import React, { useCallback, useEffect, useRef, useState } from "react";
import cartStyles from "./styles.module.css";
import { arrowLeft } from "../../assets";
import { Button, CartProduct, MayLikeItem } from "../../components";
import { LIKESDATA } from "../../utils/data";
import { handleWheel } from "../../utils/functions";
import {
  GetCartData,
  RemoveProductCart,
} from "../../redux/slices/productsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Cart() {
  const dispatch = useDispatch();
  const [cartTotal, setCartTotal] = useState(0);
  const likesRef = useRef(null);
  const timerRef = useRef(null);
  const { loading, cartData } = useSelector((state) => state.products);
  const [timeLeft, setTimeLeft] = useState(0);
  const TIMER_KEY = "cartExpiryTime";
  const EXPIRATION_DURATION = 10 * 60 * 1000;
  //============================== Dispatch Action ====================================
  useEffect(() => {
    dispatch(GetCartData());
  }, [dispatch]);
  //============================== Total Price ====================================
  useEffect(() => {
    // Calculate total from cart details
    const total = cartData?.total_price
    setCartTotal(total);
  }, [cartData]);

  //============================== Start of Cart Time Check  ================================
  const removeAllProducts = useCallback(() => {
    if (cartData?.cart_details?.length > 0) {
      cartData?.cart_details?.forEach((item) => {
        console.log("Successfully removed product:", item?.id);
        dispatch(RemoveProductCart(item?.id))
          .unwrap()
          .catch((error) => {
            console.error("Failed to remove product:", error);
          });
      });
      dispatch(GetCartData()).catch((error) => {
        console.error("Failed to fetch cart data:", error);
      });
    }
  }, [dispatch, cartData?.cart_details]);

  // Function to start the timer
  const startTimer = useCallback(
    (timeRemaining) => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }

      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;

          if (newTime <= 0) {
            clearInterval(timerRef.current);
            removeAllProducts();
            localStorage.removeItem(TIMER_KEY);
            return 0;
          }

          return newTime;
        });
      }, 1000);
    },
    [removeAllProducts]
  );

  // Check for cart expiration time in local storage on page load
  useEffect(() => {
    const cartExpiryTime = parseInt(localStorage.getItem(TIMER_KEY), 10);
    const currentTime = Date.now();

    if (cartExpiryTime) {
      const timeLeft = Math.max((cartExpiryTime - currentTime) / 1000, 0);
      if (timeLeft <= 0) {
        removeAllProducts();
        localStorage.removeItem(TIMER_KEY);
      } else {
        setTimeLeft(timeLeft);
        startTimer(timeLeft);
      }
    }

    // Cleanup on component unmount
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [removeAllProducts, startTimer]);

  // Monitor cart changes
  useEffect(() => {
    if (cartData?.cart_details?.length > 0) {
      const currentTime = Date.now();
      let cartExpiryTime = parseInt(localStorage.getItem(TIMER_KEY), 10);

      if (!cartExpiryTime || currentTime > cartExpiryTime) {
        cartExpiryTime = currentTime + EXPIRATION_DURATION;
        localStorage.setItem(TIMER_KEY, cartExpiryTime);
      }

      const timeLeft = Math.max((cartExpiryTime - currentTime) / 1000, 0);
      setTimeLeft(timeLeft);
      startTimer(timeLeft);
    } else {
      localStorage.removeItem(TIMER_KEY);
      setTimeLeft(0);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
  }, [cartData, startTimer, EXPIRATION_DURATION]);

  // Format the time in a readable format
  const formatTime = (seconds) => {
    const totalSeconds = Math.floor(seconds);
    const minutes = Math.floor(totalSeconds / 60);
    const secs = totalSeconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };
  //============================== End of Cart Time Check  ================================

  const removeProduct = (id) => {
    dispatch(RemoveProductCart(id))
      .unwrap()
      .then(() => {
        dispatch(GetCartData());
      });
  };

  return (
    <main>
      <section className="mt-4 mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className={`${cartStyles.cart_box} p-3 col-11 col-md-7 me-md-3 flex-grow-1`}
            >
              <div
                className={`${cartStyles.cart_title_box} d-flex justify-content-between align-items-baseline pb-md-4 ps-2 pe-4 pt-2 pt-md-0`}
              >
                <p className={cartStyles.cart_title}>shopping cart</p>
                {cartData?.cart_details?.length > 0 && (
                  <p className={cartStyles.items_num}>
                    After {formatTime(timeLeft)} Min The Products Will be
                    removed
                  </p>
                )}

                <p className={cartStyles.items_num}>
                  {cartData?.cart_details?.length} items
                </p>
              </div>
              <div className="horizontal_line d-none d-lg-block" />
              <div className="container-fluid mt-3">
                <div className="row align-items-baseline mb-2 d-none d-md-flex">
                  <p className={`${cartStyles.shopping_cart_title} col-5`}>
                    product details
                  </p>
                  <p className={`${cartStyles.shopping_cart_title} col-3`}>
                    quantity
                  </p>
                  <p className={`${cartStyles.shopping_cart_title} col-2`}>
                    price
                  </p>
                  <p className={`${cartStyles.shopping_cart_title} col-2`}>
                    total
                  </p>
                </div>
                <div className="horizontal_line d-none d-md-block d-lg-none mb-4" />
                {cartData?.cart_details?.length > 0 &&
                  cartData?.cart_details?.map((item, index) => (
                    <CartProduct
                      product={item}
                      onRemove={() => !loading && removeProduct(item?.id)}
                      key={item?.id}
                    />
                  ))}
                <Link to="/shop">
                  <div
                    className={`${cartStyles.continue_box} d-none d-md-flex align-items-baseline`}
                  >
                    <div className={cartStyles.continue_arrow}>
                      <img src={arrowLeft} alt="arrow left" className="img" />
                    </div>
                    <p className={cartStyles.continue_txt}>continue shopping</p>
                  </div>
                </Link>
              </div>
            </div>
            <div
              className={`${cartStyles.order_box} py-3 col-11 col-md-4 px-3 px-md-0`}
            >
              <div className={`${cartStyles.order_title_box} px-md-3 px-lg-0`}>
                <p className={`${cartStyles.cart_title} pb-0 pb-lg-3 px-lg-3`}>
                  order summary
                </p>
                <div className="horizontal_line" />
              </div>
              <div className="d-flex justify-content-between px-md-3 mt-4 mt-lg-3">
                <p className={cartStyles.items_title}>
                  items {loading ? "..." : cartData?.cart_details?.length}
                </p>
                <p className={cartStyles.items_price}>
                  {loading ? "..." : cartTotal} egp
                </p>
              </div>
              <div className="horizontal_line" />
              <div className="d-flex justify-content-between px-md-3 mt-lg-5 mb-lg-4 mt-3 mb-1">
                <p className={cartStyles.total}>total</p>
                <p className={cartStyles.total_price}>
                  {loading ? "..." : cartTotal} egp
                </p>
              </div>
              <Button
                to={"/checkout"}
                title={"checkout"}
                style={`${cartStyles.checkout_btn} mx-md-3`}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </section>
      {/* may_like */}
      <section className={cartStyles.may_like}>
        <div className="container">
          <p className={cartStyles.may_title}>you may like</p>
          <div
            className={`${cartStyles.likes_box} d-flex mt-0`}
            onWheel={(event) => handleWheel(event, likesRef)}
            ref={likesRef}
          >
            {LIKESDATA.map((item, index) => (
              <MayLikeItem item={item} key={index} />
            ))}{" "}
          </div>
        </div>
      </section>
    </main>
  );
}

export default Cart;
