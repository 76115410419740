import React from "react";
import privacyStyles from './styles.module.css'

const PrivacyPolicy = () => {
    return (
        <main>
            <section className="my-5">
                <div className="container">
                    <>
                        <div className='d-flex justify-content-center'>
                            <h3 className={`${privacyStyles.privacy_title} mb-4`}>privacy policy</h3>
                        </div>
                        <p className={privacyStyles.privacy_txt}>
                            This Privacy Policy sets out the policy of Trejars with respect to the way we obtain, use, and disclose information about you through website
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We understand and appreciate that you are concerned about privacy, particularly in relation to the use and disclosure of personal information. We are committed to providing a high level of privacy in relation to all personal information that is collected by us. The purpose of this Privacy Policy is to tell you what kind of information we may gather about you when you visit our site, how we may use that information, whether we disclose it to anyone, and the choices you have regarding how we will use your personal information, and your ability to correct this information. Our website allows you to choose what kind and how much information you provide to us and to control how we use whatever information you give us. Our goal is to provide you with a satisfying experience while allowing you to control your privacy and to give you the means to voice any questions or concerns you may have
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-4 mb-1`}>YOUR CONSENT</p>
                        <p className={privacyStyles.privacy_txt}>
                            You consent to your personal information being used in accordance with the privacy policy by visiting our website, by entering a competition on our website, by purchasing our products on the website and/or by providing us with your personal information on the website.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-4 mb-1`}>COLLECTION OF INFORMATION</p>
                        <p className={privacyStyles.privacy_txt}>
                            Information is collected from you primarily to make it easier and more rewarding for you to use our website and services. Depending on the service you are accessing, you could be asked to provide information such as your name, email address or information about what you like and do not like. It is entirely your choice whether to respond to these questions or not.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-4 mb-1`}>USE OF PERSONAL INFORMATION</p>
                        <p className={privacyStyles.privacy_txt}>
                            Trejars will use the personal information you have chosen to provide us for the purpose for which you provided it. Trejars will not use it for any other purpose without your consent. We might on occasions, use this information to notify you of any important changes to our site or any special promotions that may be of interest to you. On each email or communication that we send you, we will include simple instructions on how you can immediately unsubscribe from our mailing list. You can opt out from receiving such material at any time by emailing our privacy manager and asking to be removed from the mailing list. Any information we may collect from you will be treated with strict confidentiality.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-4 mb-1`}>DISCLOSURE</p>
                        <p className={privacyStyles.privacy_txt}>
                            There will be occasions where it will be necessary for Trejars to disclose your personal information to third parties. Trejars may be required to disclose your personal information to third parties to provide the products or services you have requested, for example, if you purchase products online, Trejars will need to disclose your personal information to third parties to bill and deliver your products. However, the disclosure will only be made where it is necessary to fulfill the purpose for which you disclosed your personal information. Otherwise, then stated above, we do not disclose personal information that you may give us, such as your name, address, email address or telephone number, to any organization or person outside BOUNCE, unless you have authorized us to do so, or unless we are requested to do so by a law enforcement agency in relation to a suspected breach of any law.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-4 mb-1`}>PASSWORDS</p>
                        <p className={privacyStyles.privacy_txt}>
                            Ultimately, you are solely responsible for maintaining the secrecy of your passwords and/or any personal information. Be responsible whenever you are online
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            Credit Cards We do not collect or store credit card information on our database or server. This information is managed through a secure credit card payment facility, all information sent to the processing bank is managed with the latest security software, systems, and processes (SSL).
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-4 mb-1`}>SECURITY</p>
                        <p className={privacyStyles.privacy_txt}>
                            Trejars operates secure data networks that are designed to protect your privacy and security. Trejars has a Security Policy that explains how we protect your privacy and personal information.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            Please note that our website does not provide systems for secure transmission of personal information across the internet, except where otherwise specifically stated. You should be aware that there are inherent risks in transmitting personal information via the internet and that we accept no responsibility for personal information provided via unsecured websites.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We will not be held liable for loss or damage arising from unauthorized access to your personal information or a breach of this policy whether such access or breach was caused by our negligent or willful act or omission or that of our employees or agents.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-4 mb-1`}>ACCESS AND CORRECTION</p>
                        <p className={privacyStyles.privacy_txt}>
                            The security of your information is important to us. The information we have collected about you cannot be seen or modified by anyone else. We make all reasonable efforts to ensure that information is stored securely both in electronic and physical form.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            You may access your information at any time. If you would like to know what information we hold about you, please contact us. If you discover that there is an error or information is missing, please notify us and we will try to correct or update the information as soon as possible.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We will try to ensure that all information we collect, use, or disclose about you is accurate, complete and up to date.
                        </p>
                    </>
                    <>
                        <div className='d-flex justify-content-center'>
                            <h3 className={`${privacyStyles.privacy_title} mt-5 mb-4`}>سياسة الخصوصية</h3>
                        </div>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            سياسة الخصوصية لدينا هي طريقة جمعنا وحفظنا واستخدامنا وطريقة حماية معلوماتك الشخصية ونعني بمعلومات الشخصية هي المعلومات التي ترتبط بشخص معين والتي تستخدم في التعريف عنه
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            نقوم بجمع المعلومات الشخصية من الراغبين بإستخدام موقع جراندي هوم او صفحات الانترنت المرتبطة. وبمجرد تزويدك لنا بمعلوماتك الشخصية تكون قد فوضتنا لمعالجة هذه المعلومات وفق بنود وشروط (سياسة الخصوصية)
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            قد نقوم بتعديل (سياسة الخصوصية) في اي وقت وذلك من خلال الإعلان عن هذا التعديل بنشر النسخة المعدلة على الموقع. وتكون النسخة المعدلة من (سياسة الخصوصية) سارية المفعول اعتبارا من تاريخ نشرها. في اعقاب النشر يعتبر استمرارك في استخدام الموقع موافقة منك بالشروط والبنود الواردة في النسخة المعدلة لطريقة معالجتنا وتعاملنا مع معلوماتك الشخصية التي كنت قد زودتنا بها
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            نأمل منكم الإطلاع على الموقع من حين لأخر للإعلانات عن اية تعديلات على سياسة الخصوصية
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-5 mb-1 text-end`}>جمعنا لمعلوماتك الشخصية</p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            كجزء من التسجيل على الموقع أو استخدامه سوف يطب منك تزويدنا بمعلومات شخصية محددة مثل اسمك وعنوان الشحن وبريدك الالكتروني و رقم هاتفك ومعلومات اخرى مشابهة وبعض المعلومات الاضافية عنك مثل تاريخ ميلادك أو اي معلومات محددة لهويتك. إضافة إلى ذلك وبهدف توثيقنا لهويتك
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            قد نحتاج ايضا الى جمع معلومات مالية محددة منك، مثلا بطاقتك الائتمانية و/أو تفاصيل حسابك المصرفي
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            يجب عليك ادخال هذه المعلومات المالية في قسم "حسابي" أو عند اتمام الاوردر على الموقع. وسوف نستخدم هذه المعلومات لغايات اصدار الفوانير واسنكمال طلباتك. عند تسجيلك في الموقع، ينبغى عليك عدم نشر اي معلومات شخصية (بما في ذلك اي معلومات مالية) على اي جزء من الموقع باستثناء جزء" إدارة الحساب" أو صفحة اتمام الاوردر وهذا من شأنه حمايتك من امكانية تعرضك للاحتيال او سرقة معلومات هويتك
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            سوف يتم جمع معلومات عن عملياتك وأنشطتك على الموقع.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            رقم فريد يتم تعيينه لخادم الكمبيوتر أو مزود خدمة الإنترنت الخاص بك أو(ISP) لتحليل انشطتك وتحسين إدارة الموقع.
                            قد نجمع معلومات اضافية حول جهاز الكمبيوتر الخاص بك (على سبيل المثال نوع المتصفح) ومعلومات التصفح (على سبيل المثال الصفحات التي تزورها على الموقع) بالإضافة إلى عدد المرات التي تستخدم بها الموقع
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            أخيرًا، قد نجمع معلومات إضافية منك أو عنك بطرق أخرى غير موصوفة هنا تحديدًا. على سبيل المثال، قد نقوم بجمع المعلومات المتعلقة بتواصلك مع فريق دعم العملاء أو من خلال اجابتك على الاستبيانات. قد نجمع أيضًا التقييمات والتعليقات الأخرى المتعلقة باستخدامك للموقع. عندما نقوم بتجميع المعلومات الشخصية للأغراض الإحصائية، يجب أن تكون أسماء أصحاب المعلومات مجهولة
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-5 mb-1 text-end`}>استخدامنا لمعلوماتك الشخصية</p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            نستخدم معلوماتك الشخصية لتقديم خدمات ودعم من فريق العملاء وبهدف قياس مستوى خدماتنا وتحسينها لك ومنع النشاطات غير القانونية وتنفيذ لبنود اتفاقية الاستخدام الموفقة معك ("اتفاقية الاستخدام")، اضافة الى حل المشاكل وجمع الرسوم وتزويدك برسائل الكترونية ترويجية وكذلك من اجل توثيق المعلومات التي زودتنا بها مع اطراف ثالثة مثلا قد نلجأ الى مشاركة بعض من معلوماتك الشخصية مع البنوك او التفويض لبطاقات الائتمان لمعالجة وتوثيق خدمات أو مع اطراف ثالثة لغايات التحقق من عمليات الاحتيال <br />
                            برغم حرصنا للمحافظة على سرّيتك، الا اننا قد نحتاج الى الافصاح عن معلوماتك الشخصية لاجهزة تنفيذ القانون والهيئات الحكومية او اطراف ثالثة، نكون ملزمين بفعل ذلك باوامر من المحكمة اوغيرها من الدوائر القانونية لنكون ملتزمين ببنود القانون أو عند اعتقادنا ان الافصاح عن معلوماتك الشخصية ربما يقي من أذى جسدي او خسارة مالية او للاخبار عن نشاط مشبوه او للتحقيق في امكانية انتهاك لبنود وشروط اتفاقية المستخدم واية اتفاقيات أو لحماية الحقوق أو ملكية أو سلامة أومستخدمينا أو الغير <br />
                            سيتم عرض المعلومات المتعلقة بالمنتجات التي تقوم بشرائها على الموقع. يمكن أن تتضمن هذه المعلومات تفاصيل حول هوية المستخدم الخاصة بك والتقييمات والتعليقات المرتبطة باستخدامك للموقع. <br />
                            لا نبيع أو نؤجر اي من معلوماتك الشخصية لطرف ثالث ضمن نطاق عملنا التجاري المعتاد وسوف نشارك فقط معلوماتك الشخصية فقط وفق ما جاء في (سياسة الخصوصية) هذا <br />
                            بمجرد تسجيلك في أو استخدامك للموقع تعتبر أنك أعطيتنا تفويضا واضحا لتسلمك رسائل إلكترونية ترويجية حول خدماتنا إضافة الى رسائل إلكترونية بخصوص التغيرات والملامح الجديدة على الموقع ، بحال عدم رغبتك في تلقي مثل هذه الرسائل ,ماعليك سوى إختيار الرابط الخاص بإيقاف إرسال هذه الرسائل إلى عنوانك البريدي والمتوفر في اسفل اي من هذه الرسائل الإلكترونية <br />
                            بالاضافة الى ذلك نقوم باستخدام ملاحظاتك حول الموقع لغايات التسويق ومن خلال تقديمك لهذه الملاحظات تكون وافقت على أن نستخدم هذه الملاحظات لغايات التسويق <br />
                            سيتم الافصاح عن بعض البيانات الشخصية المتعلقة بك مع شركات الشحن مثل الاسم، العنوان، رقم الهاتف
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-5 mb-1 text-end`}>إستخدام ودخول وتصفح وتعديل معلوماتك الشخصية</p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            باستطاعتك الوصول ومراجعة معلوماتك الشخصية من خلال صفحة إدارة "حسابي"على الموقع، فاذا تغيرت معلوماتك الشخصية باي طريقة او اعطيت معلومات غير صحيحة على الموقع فعليك القيام بتحديثها او تصحيحها حالا، اما من خلال عرضها على "حسابي" او من خلال الاتصال بفريق خدمة العملاء. موضح على الموقع رقم التليفون والبريد الاكتروني الخاصين بخدمة العملاء
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            يرجى العلم أننا سوف نحتفظ بمعلوماتك الشخصية خلال وبعد انتهائك من استخدام الموقع بحسب ما هو مطلوب قانونا، وذلك بهدف التغلب على العوائق التقنية ,ولمنع الاحتيال، و للمساعدة في اي تحقيق قانوني ولاتخاذ اي اجراءات اخرى ينص عليها القانون
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-5 mb-1 text-end`}> (cookies)  ملفات تعريف الارتباط</p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            مثل العديد من مواقع الانترنت فان موقعنا يستخدم ملفات تعريف الإرتباط وهي ملفات حاسوب صغيرة يتم تثبيتها على الجزء الصلب من حاسوبك، فحين تذهب لزيارة صفحات انترنت معينة على الموقع, تتعرف السجل على المتصفح الخاص بك من خلال رقم فريد وعشوائي
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            كما ان ملفات تعريف الإرتباط التي نستخدمها لا تكشف اي من معلوماتك الشخصية.ان ملفات تعريف الإرتباط هذه تساعدنا في تحسين ادائك على الموقع وتعيننا على تفهم اي اجزاء من الموقع هي الاكثر استخداما,انت لك مطلق الحرية في الغاء هذه ملفات تعريف الإرتباط اذا أتيح لك ذلك من المتصفح ولا يؤثر ذلك على استخدامك للموقع
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-5 mb-1 text-end`}>حماية معلوماتك الشخصية</p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            عندما تزودنا بمعلوماتك الشخصية نتخذ كافة الاحتياطات للمحافظة على معلوماتك وذلك بعدم الوصول اليها او استخدامها او الافصاح عنها بشكل غير مخول
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            كافة المعلومات الشخصية مشفرة، وعلى اي حال فان الانترنت وسيلة غير امنة ونحن لا نضمن سرية معلوماتك الشخصية
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            يجب ان يتم ادخال اسم المستخدام وكلمة السر في كل مرة عند الدخول الى حسابك أو القيام باية معامللات في الموقع
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            يجب ان يتم اختيار كلمة السر بحذر باستخدام ارقام وحروف واشكال مميزة
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            لا تشارك اسم المستخدم وكلمة السر مع اي احد
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            في حال كان لديك شك حول اختراق اسم المستخدم وكلمة السر، يرجى على الفور الاتصال بفريق خدمة العملاء والعمل على تغيير كلمة السر وذلك بالدخول الى قسم حسابي على الموقع
                        </p>
                    </>
                </div>
            </section>
            <section className="my-5">
                <div className="container">
                    <>
                        <div className='d-flex justify-content-center'>
                            <h3 className={privacyStyles.privacy_title}>Terms and Conditions</h3>
                        </div>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>OVERVIEW</p>
                        <p className={privacyStyles.privacy_txt}>
                            This website is operated by Trejars. Throughout the site, the terms “we”, “us” and “our” refer to TrejarsTrejars offers this website, including all information, tools, and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            Our store is hosted on Shopify Inc. They provide us with the online e-commerce platform that allows us to sell our products and services to you.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 2 - GENERAL CONDITIONS</p>
                        <p className={privacyStyles.privacy_txt}>
                            We reserve the right to refuse service to anyone for any reason at any time.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</p>
                        <p className={privacyStyles.privacy_txt}>
                            We are not responsible if information made available on this site is not accurate, complete, or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete, or more timely sources of information. Any reliance on the material on this site is at your own risk.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</p>
                        <p className={privacyStyles.privacy_txt}>
                            Prices for our products are subject to change without notice.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 5 - PRODUCTS OR SERVICES (if applicable)</p>
                        <p className={privacyStyles.privacy_txt}>
                            Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region, or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</p>
                        <p className={privacyStyles.privacy_txt}>
                            We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. If we make a change to or cancel an order, we may attempt to notify you by contacting the e‑mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            You agree to provide current, complete, and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            For more detail, please review our Returns Policy.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 7 - OPTIONAL TOOLS</p>
                        <p className={privacyStyles.privacy_txt}>
                            We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations, or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 8 - THIRD-PARTY LINKS</p>
                        <p className={privacyStyles.privacy_txt}>
                            Certain content, products and services available via our Service may include materials from third parties.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third parties.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</p>
                        <p className={privacyStyles.privacy_txt}>
                            If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive, or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e‑mail address, pretend to be someone other than yourself, or otherwise mislead us or third parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 10 - PERSONAL INFORMATION</p>
                        <p className={privacyStyles.privacy_txt}>
                            Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</p>
                        <p className={privacyStyles.privacy_txt}>
                            Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 12 - PROHIBITED USES</p>
                        <p className={privacyStyles.privacy_txt}>
                            In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</p>
                        <p className={privacyStyles.privacy_txt}>
                            We do not guarantee, represent, or warrant that your use of our service will be uninterrupted, timely, secure or error-free.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            In no case shall Trejars , our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 14 - INDEMNIFICATION</p>
                        <p className={privacyStyles.privacy_txt}>
                            You agree to indemnify, defend and hold harmless Trejars and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 15 - SEVERABILITY</p>
                        <p className={privacyStyles.privacy_txt}>
                            If any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 16 - TERMINATION</p>
                        <p className={privacyStyles.privacy_txt}>
                            The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 17 - ENTIRE AGREEMENT</p>
                        <p className={privacyStyles.privacy_txt}>
                            The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 18 - GOVERNING LAW</p>
                        <p className={privacyStyles.privacy_txt}>
                            These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Egypt.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>SECTION 19 - CHANGES TO TERMS OF SERVICE</p>
                        <p className={privacyStyles.privacy_txt}>
                            You can review the most current version of the Terms of Service at any time at this page.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            Trejars has the full right to suspend any account that conducts speculative operations without prior warning.
                        </p>
                    </>
                    <>
                        <div className='d-flex justify-content-center'>
                            <h3 className={`${privacyStyles.privacy_title} mt-5 mb-4`}>سياسة الاستخدام</h3>
                        </div>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            كجزء من سياسة الاستخدام المقبول Trejars، فإنك توافق على عدم استخدام المعلومات أو الخدمات أو أي جزء آخر من منصة Trejars لاتخاذ أي إجراء أو إجراءات من شأنها:
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ١. تتعارض مع صورة   Trejars  العامة أو النية الحسنة أو السمعة.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ٢. الترويج لأي نشاط أو محتوى غير قانوني، بما في ذلك على سبيل المثال لا الحصر إساءة معاملة الأطفال أو الحيوانات والعنف والاستخدام غير المشروع للمخدرات والشرب دون السن القانونية.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ٣. تنتهك حقوق الطبع والنشر أو براءات الاختراع أو العلامات التجارية أو الأسرار التجارية أو غيرها من حقوق الملكية أو حقوق الدعاية أو الخصوصية الخاصة بنا أو بشركات أخري.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ٤. عدم مشاركة صريحة أو ضمنية أيًا من بياناتك أو أنشطتك أو أسبابك قد أقرناها، دون موافقتنا الخطية المسبقة في كل حالة.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ٥. انتهاك أي قانون أو قانون أو مرسوم أو لائحة معمول بها في البلاد، أو تشجيع أي سلوك يمكن أن يشكل جريمة جنائية أو ينشأ عنه مسؤولية مدنية.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ٦. التشهير أو تشهيرية أو تهديدية أو مضايقة أو اقتحام للخصوصية أو مسيئة أو مؤذية أو كراهية أو تمييزية أو إباحية أو فاحشة.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ٧. إرسال أي سر تجاري أو معلومات أخرى أو معلومات غير عامة عن أي شخص أو شركة أو كيان دون الحصول على إذن بذلك.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ٨. تقييد أو منع أي زائر آخر من استخدام منصة  Trejars، بما في ذلك، على سبيل المثال لا الحصر، عن طريق "اختراق" أو تشويه أي جزء من منصة  Trejars أو تعديل، تتغيير، ترخيص فرعي، ترجمة، بيع، هندسة عكسية، إلغاء ترجمة، أو فك أي جزء من منصة  Trejars
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ٩. إزالة أي حقوق نشر أو علامة تجارية أو غيرها من إشعارات حقوق الملكية الواردة في منصة Trejars.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ١٠. الباطن أو البيع أو الإيجار أو التأجير أو النقل أو التنازل عن أو نقل أي حقوق بموجب الشروط إلى أي طرف ثالث، أو الاستغلال التجاري أو الربح بطريقة أخرى من المعلومات أو محتوى منصة Trejars، أو أي جزء منها، بأي شكل من الأشكال، باستثناء ما هو مسموح به صراحة هنا.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ١١. "طباعه" أو "نسخ" أي جزء من منصة  Trejarsدون إذن خطي مسبق منا.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ١٢. توزيع أي فيروس أو فيروس متنقل أو أي ملفات أو نصوص برمجية أو برامج مشابهة أو ضارة.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ١٣. التدخل مع أو تعطل أي خدمات أو معدات بقصد التسبب في حمل زائد أو غير متناسب على البنية التحتية لشركة  Trejars أو الجهات المرخصة لها أو مورديها.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ١٤. تنطوي على إعداد أو توزيع البريد غير المرغوب فيه أو الرسائل غير المرغوب فيها أو سلسلة الرسائل أو المخططات الهرمية أو غيرها من رسائل البريد الإلكتروني التجارية الضخمة أو غير المرغوب فيها أو تنتهك بأي شكل من الأشكال قانون مكافحة الاعتداء على المواد الإباحية والتسويق غير المسموح بها (كان سبام أكت ٢٠٠٣) أو ما يعادلها، المعمول بها، القانون الأجنبي.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ١٥. استخدام أي روبوت، أو شبكة، أو تطبيق للبحث / استرجاع الموقع، أو أي جهاز أو عملية أخرى يدوية أو أوتوماتيكية لاسترداد أو فهرسة "مخزن البيانات" في منصة  Trejars أو إعادة إنتاج أو التحايل على أي شكل من الأشكال الهيكل الملاحي أو العرض التقديمي لمنصة  Trejarsأو المحتويات والمصادقة والتدابير الأمنية.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ١٦. تزوير العناوين أو الهويات أو التلاعب بمعرفات الهوية من أجل إخفاء أصل أي إرسال.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ١٧. تنفيذ أي شكل من أشكال مراقبة الشبكة أو تشغيل محلل شبكة أو حزمة بحث أو تقنية أخرى لاعتراض أو فك شفرة أو استخراج أو عرض أي حزم تستخدم للاتصال بين خوادم  Trejars للنظام الأساسي أو أي بيانات غير مخصصة لك ، الصورة. حصاد أو جمع معلومات حول أي زائر أو أعضاء من منصة  Trejars دون موافقتهم الصريحة؛ و / أو
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`} dir="rtl">
                            ١٨. يحتوي على أي إشارة إلى أو تشبه أي أطراف ثالثة محددة، ما لم يتم الحصول على موافقة من جميع هؤلاء الأفراد وأولياء أمورهم / الوصي القانوني إذا كانوا تحت سن الرشد حسب قانون الدولة..
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            لها كامل الحق لأيقاف اي حساب يقوم بعمليات مضاربية دون انذار مسبق Trejars منصة
                        </p>
                    </>
                </div>
            </section>
            <section className="my-5">
                <div className="container">
                    <div className='d-flex justify-content-center'>
                        <h3 className={privacyStyles.privacy_title}>Cancelation & refund policy</h3>
                    </div>
                    <p className={`${privacyStyles.privacy_sub_title} mb-0`}>1. GENERAL</p>
                    <p className={privacyStyles.privacy_txt}>
                        Trejars is committed to providing exceptional service in a timely manner Unfortunately,
                    </p>
                    <p className={privacyStyles.privacy_txt}>
                        when customer cancels without giving enough notice, it prevents another customer from being served. No shows and late cancellation have an impact on service quality while punishing customers who may show up earlier. For these reasons. Trejars has implemented a cancellation policy that will be strictly observed.
                    </p>
                    <p className={`${privacyStyles.privacy_sub_title} mb-0 mt-3`}>2. FULL PAYMENT</p>
                    <p className={privacyStyles.privacy_txt}>
                        Your registration is complete when we receive your full payment. Payments can be online or in person. We do not reserve Gold, Silver , precious metals or actions without payment.
                    </p>
                    <p className={privacyStyles.privacy_txt}>
                        An online confirmation email will be sent to you at the time of registration and payment. This email serves as confirmation of your registration.
                    </p>
                    <p className={`${privacyStyles.privacy_sub_title} mb-0 mt-3`}>3. CANCELLATION REQUEST</p>
                    <p className={privacyStyles.privacy_txt}>
                        Cancellation requests may be submitted by phone, email, online or in person before buying products. Please note that refunds will be processed in the original form of payment. If you have any questions or concerns about our cancellation policy, please contact us at +201027815611
                    </p>
                    <p className={privacyStyles.privacy_txt}>
                        Any cancellation requests will not be accepted if any customer already made the buy order.
                    </p>
                    <p className={`${privacyStyles.privacy_sub_title} mb-0 mt-3`}>4. REFUND POLICY</p>
                    <p className={privacyStyles.privacy_txt}>
                        As precious metals have live price changing we will refund according to the international live price of products ( Gold, Silver & precious metals ) not to the paid amount.
                    </p>
                </div>
            </section>
            <section className="my-5">
                <div className="container">
                    <div className='d-flex justify-content-center'>
                        <h3 className={privacyStyles.privacy_title}>delivery policy</h3>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <h3 className={`${privacyStyles.privacy_title} mt-1 mb-4`}>(1)</h3>
                    </div>
                    <p className={privacyStyles.privacy_txt}>
                        Please be advised that the official business days for Trejars from Sunday to Thursday. Fridays, Saturdays, holidays, and public holidays its holidays for all employees of Trejars and shipping companies. <br />
                        User can request a fast delivery option for more fees if available from our side. <br />
                        Our first mission is to offer you the best online shopping experience, so we have made it easier for you to make your shipment easier because your convenience is our priority <br />
                        Trejars mainly delivers your orders through a third party guarded delivery to ensure the timely & safely shipping of your order. <br />
                        The method of delivery at Trejars is to deliver within two to four days as a maximum, depending on your geographical area, giving you the option to choose the right time for you to deliver at any time during the day, week or even during the month. Some orders can take longer times according to the customer request for special designs. <br />
                        In the case of urgent orders, please contact our customer service and we will do our best to help you. <br />
                        We will ask for your signature on a copy of the invoice to confirm receipt of the goods. We will deliver the order to the registered address, and we consider the signature of anyone at the address as a receipt of the order. <br />
                        If there is no one at the registered address to receive your order, we will ask you to contact our customer service to arrange your visit to our store to collect your ordered product. The company is not able to attempt delivery more than one time. The company is not responsible for any order after delivery.
                    </p>
                    <div className='d-flex justify-content-center'>
                        <h3 className={`${privacyStyles.privacy_title} mt-5 mb-4`}>(2)</h3>
                    </div>
                    <p className={privacyStyles.privacy_txt}>
                        In-stock Orders are fulfilled within 2-7 business days. <br />
                        Custom-made Orders are fulfilled according to individual production time which is stated on the product description page.
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>Delivery cost is either</p>
                        Calculated at check-out depending on the product and the delivery address <br />
                        Quoted after order for special orders, and included on your order’s final balance
                    </p>
                    <div className='d-flex justify-content-center'>
                        <h3 className={`${privacyStyles.privacy_title} mt-5 mb-4`}>(3)</h3>
                    </div>
                    <p className={`${privacyStyles.privacy_sub_title} mb-0`}>Delivery Time</p>
                    <p className={privacyStyles.privacy_txt}>
                        An estimated delivery time will be provided to you once your order is placed. Delivery times are estimates and commence from the date of shipping, rather than the date of order. Delivery times are to be used as a guide only and are subject to the acceptance and approval of your order. <br />
                        Unless there are exceptional circumstances, we make every effort to fulfill your order within 5 business days of the date of your order. Business days mean Saturday to Friday, except holidays. <br />
                        Date of delivery may vary due to carrier shipping practices, delivery location, method of delivery, and the items ordered. Products may also be delivered in separate shipments. <br />
                        To find out how much your order will cost, simple add the items you would like to purchase to your cart, and proceed to the checkout page. Once at the checkout screen, shipping charges will be displayed.
                    </p>
                </div>
            </section>
        </main>
    );
};

export default PrivacyPolicy;
