import React, { useEffect, useState } from "react";
import dashStyles from "../Dashboard/styles.module.css";
import historyStyles from "./styles.module.css";
import { Button, OrderPDF } from "../../components";
import { download } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import { GetOrderDetails } from "../../redux/slices/productsSlice";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import OrderDetailsModal from "./OrderDetailsModal";

function OrderHistory({ pageID, activePageID, styles }) {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const { loading, user } = useSelector((state) => state.auth);
  const { OrderDetails } = useSelector((state) => state.products);
  const [showModal, setShowModal] = useState(false);
  const [orderDetailsData, setOrderDetailsData] = useState(null);

  useEffect(() => {
    dispatch(GetOrderDetails({ user_id: user?.id }));
  }, [dispatch]);

  useEffect(() => {
    if (activePageID === pageID) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [activePageID]);

  // ============= DownLoad invoice =========
  const orderDetailsModalHandler = (orderData) => {
    setShowModal(true);
    setOrderDetailsData(orderData);
  };
  // ============= DownLoad invoice =========
  const downloadPdf = async (Orderdata) => {
    const blob = await pdf(<OrderPDF data={Orderdata} />).toBlob();
    !loading && saveAs(blob, "sample.pdf");
  };
  return (
    <>
      <div
        className={`${isActive
            ? dashStyles.active_container
            : dashStyles.in_active_container
          } col-11 col-md-8 flex-md-grow-1 gray_box px-2 px-lg-3 pt-4 pb-3 d-none d-md-block ${styles}`}
      >
        <p className="dash_title mb-5">order history</p>
        <div className="row">
          {/*<p className={`${historyStyles.table_title} col-1`}>Order ID</p>*/}
          <p className={`${historyStyles.table_title} col-2 col-lg-1`}>
            product ID
          </p>
          <p className={`${historyStyles.table_title} col-3`}>
            name <span>of</span> product
          </p>
          <p className={`${historyStyles.table_title} col-1 col-lg-2`}>total</p>
          <p className={`${historyStyles.table_title} col-2`}>status</p>
          <p className={`${historyStyles.table_title} col-2`}>view</p>
          <p className={`${historyStyles.table_title} col-2`}>
            Download Invoice
          </p>
        </div>
        {OrderDetails?.map((order, index) => (
          <div className="row my-4" key={index}>
            <p className={`${historyStyles.table_data} col-2 col-lg-1`}>
              {order?.cart[0]?.product.id}
            </p>
            <p className={`${historyStyles.table_data} col-3`}>
              {order?.cart[0]?.product.name}
            </p>

            <p className={`${historyStyles.table_data} col-1 col-lg-2`}>
              {order?.order?.amount}
            </p>
            <p className={`${historyStyles.table_data} col-2`}>
              {order?.order?.status}
            </p>
            <div className="col-2">
              <Button
                title={"view"}
                style={`${historyStyles.view_btn} w-100 py-1`}
                onClick={() => orderDetailsModalHandler(order)}
              />
            </div>
            <div className="col-2 d-flex justify-content-center">
              <div
                className={historyStyles.download_box}
                onClick={() => downloadPdf(order)}
              >
                <img src={download} alt="download logo" className="img" />
              </div>
            </div>
          </div>
        ))}
      </div>
      {showModal && (
        <OrderDetailsModal
          orderDetailsdata={orderDetailsData}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
}

export default OrderHistory;
