import {
  like1,
  like10,
  like11,
  like12,
  like13,
  like2,
  like3,
  like4,
  like5,
  like6,
  like7,
  like8,
  like9,
} from "../../assets";

export const LIKESDATA = [
  { id: 1, url: like1, title: "21 karat gold necklace" },
  { id: 2, url: like2, title: "21 karat gold necklace" },
  { id: 3, url: like3, title: "21 karat gold necklace" },
  { id: 4, url: like4, title: "21 karat gold necklace" },
  { id: 5, url: like5, title: "21 karat gold necklace" },
  { id: 6, url: like6, title: "21 karat gold necklace" },
  { id: 7, url: like7, title: "21 karat gold necklace" },
  { id: 8, url: like8, title: "21 karat gold necklace" },
  { id: 8, url: like9, title: "21 karat gold necklace" },
  { id: 8, url: like10, title: "21 karat gold necklace" },
  { id: 8, url: like11, title: "21 karat gold necklace" },
  { id: 8, url: like12, title: "21 karat gold necklace" },
  { id: 8, url: like13, title: "21 karat gold necklace" },
];

export const EGYPTCITIES = [
  { name: "Cairo", zip: "12494", fees: 330 },
  { name: "Alexandria", zip: "21481", fees: 480 },
  { name: "Ismailia", zip: "15485", fees: 480 },
  { name: "Giza", zip: "15485", fees: 380 },
  { name: "Port Said", zip: "54687", fees: 480 },
  { name: "Suez", zip: "65490", fees: 480 },
  { name: "Dakahlia", zip: "34562", fees: 480 },
  { name: "Beheira", zip: "45678", fees: 480 },
  { name: "Faiyum", zip: "12345", fees: 780 },
  { name: "Gharbia", zip: "98765", fees: 480 },
  { name: "Monufia", zip: "23456", fees: 480 },
  { name: "Minya", zip: "34567", fees: 780 },
  { name: "Qalyubia", zip: "45678", fees: 480 },
  { name: "Luxor", zip: "67890", fees: 980 },
  { name: "Asyut", zip: "89012", fees: 780 },
  { name: "Sharqia", zip: "90123", fees: 480 },
  { name: "Damietta", zip: "01234", fees: 480 },
  { name: "Kafr El Sheikh", zip: "12345", fees: 480 },
  { name: "Bani Suwayf", zip: "34567", fees: 780 },
  { name: "Sohag", zip: "45678", fees: 780 },
  { name: "Qena", zip: "56789", fees: 980 },
];
