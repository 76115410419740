import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const OrderPDF = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.grayBox}>
        <Text style={styles.mainTitle}>Order Details</Text>
        <View style={styles.lineData}>
          <Text style={styles.orderTitle}>Total Order</Text>
          <Text style={styles.orderText}>{data?.cart?.reduce((total, item) => total + item.quantity, 0)} items</Text>
        </View>
        <View style={styles.lineData}>
          <Text style={styles.orderTitle}>Order Id</Text>
          <Text style={styles.orderText}>{data?.order?.id}</Text>
        </View>
        <View style={styles.lineData}>
          <Text style={styles.orderTitle}>Payment Method</Text>
          <Text style={styles.orderText}> {data?.order?.payment_method}</Text>
        </View>
        <View style={styles.lineData}>
          <Text style={styles.orderTitle}>Status</Text>
          <Text style={styles.orderText}>{data?.order?.status}</Text>
        </View>
        <View style={styles.lineData}>
          <Text style={styles.orderTitle}>Total Amount</Text>
          <Text style={styles.orderText}>
            {data?.order?.amount} {data?.order?.currency}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  grayBox: {
    backgroundColor: "#1D1D1D",
  },
  lineData: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 15,
    paddingHorizontal: 150,
  },
  mainTitle: {
    color: "#FCC65B",
    fontWeight: "bold",
    fontSize: 15,
    textAlign: "center",
    marginVertical: 25,
  },
  orderTitle: {
    color: "#A7833F",
    fontWeight: "bold",
    fontSize: 11,
  },
  orderText: {
    color: "#FCC65B",
    fontSize: 10,
  },
});

export default OrderPDF;
