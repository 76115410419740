import React, { useEffect, useRef, useState } from "react";
import shopStyles from './styles.module.css'
import cartStyles from '../Cart/styles.module.css'
import { AppBanner, Button, MayLikeItem, ProductItem } from "../../components";
import { checkBox, checkedBox, close, contactBanner, filter } from "../../assets";
import { LIKESDATA } from "../../utils/data";
import { handleWheel } from "../../utils/functions";
import MultiRangeSlider from "multi-range-slider-react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { filterProducts, GetAllProducts, getFilterCategories, getFilterData, searchForProduct } from "../../redux/slices/productsSlice";

function Shop() {
  const likesRef = useRef(null);
  const [selectedType, setSelectedType] = useState('');
  const [selectedWeight, setSelectedWeight] = useState('');
  const [selectedKarat, setSelectedKarat] = useState('');
  const [selectedCat, setSelectedCat] = useState('');
  const [minValue, setMinValue] = useState(3500);
  const [maxValue, setMaxValue] = useState(1000000);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleRangeChange = (min, max) => {
    setMinValue(min);
    setMaxValue(max);
  };

  const isMobile = useMediaQuery({ maxWidth: "767px" });

  useEffect(() => {
    if (!isMobile) {
      setIsFilterOpen(false)
    }
  }, [isMobile]);

  useEffect(() => {
    if (isFilterOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isFilterOpen]);

  const dispatch = useDispatch();
  const intervalId = useRef(null);

  useEffect(() => {
    dispatch(GetAllProducts());
    dispatch(searchForProduct(''))
    dispatch(getFilterCategories())
    dispatch(getFilterData())
    const reFetchData = () => {
      dispatch(GetAllProducts());
    };
    intervalId.current = setInterval(reFetchData, 30000);
    return () => clearInterval(intervalId.current);
  }, []);

  useEffect(() => {
    const filterData = {}
    selectedType && (filterData['type'] = selectedType);
    selectedWeight && (filterData['Weight'] = selectedWeight);
    selectedKarat && (filterData['Karat'] = selectedKarat);
    selectedCat && (filterData['category'] = selectedCat);
    minValue !== 3500 && (filterData['price_min'] = minValue);
    maxValue !== 1000000 && (filterData['price_max'] = maxValue);
    Object.keys(filterData).length > 0 && dispatch(filterProducts(filterData))
  }, [selectedType, selectedWeight, selectedKarat, selectedCat, minValue, maxValue]);

  const { allProducts, searchOrFilterData, categories, filteringData } = useSelector(state => state.products);

  return (
    <main className="bg_dark">
      <div className="mt-lg-4 mb-5">
        <AppBanner url={contactBanner} title={'shop'} />
      </div>
      <div className="container">
        <div className="row mb-5">
          <div className={`${isFilterOpen ? shopStyles.filter_box : shopStyles.filter_box_closed} px-3 px-md-0 py-3 py-md-0 col-md-4 col-lg-3 me-md-2`}>
            {isFilterOpen && (
              <div className="d-flex justify-content-between align-items-baseline px-1">
                <div className="d-flex align-items-center mb-3">
                  <img src={filter} alt="filter icon" className={shopStyles.filter_icon} />
                  <p className={`${shopStyles.filter_txt_big} mb-0 ms-1`}>filter</p>
                </div>
                <img src={close} alt="close" className={shopStyles.filter_icon} onClick={() => setIsFilterOpen(false)} />
              </div>
            )}
            <div className="gray_box px-2 py-3 mt-3">
              <div className={shopStyles.fit_box}>
                <p className={`${shopStyles.filter_title} mb-1`}>Filter by type</p>
                <div className="horizontal_line mb-3" />
              </div>
              {filteringData?.types?.map(type => (
                <div className="d-flex align-items-center justify-content-between pe-md-4 pe-xlg-5 mb-2" key={type?.type}>
                  <div className="d-flex align-items-center">
                    <div onClick={() => setSelectedType(type?.type)} className="c-pointer">
                      {selectedType === type?.type ? (
                        <img src={checkedBox} alt="checked box" />
                      ) : (
                        <img src={checkBox} alt="check box" />
                      )}
                    </div>
                    <p className={`${shopStyles.checkbox_txt} ms-2 mb-0`}>{type?.type}</p>
                  </div>
                  <p className={`${shopStyles.type_items} mb-0`}>{type?.count} items</p>
                </div>
              ))}
            </div>
            <div className="gray_box px-2 py-3 mt-3">
              <div className={shopStyles.fit_box}>
                <p className={`${shopStyles.filter_title} mb-1`}>Filter by Weight</p>
                <div className="horizontal_line mb-3" />
                <div className="container-fluid">
                  <div className="pe-4 row gap-3 column-gap-lg-2 column-gap-xl-3">
                    {filteringData?.weights.map((Weight) => (
                      <p className={`${selectedWeight === Weight.Weight ? shopStyles.selected_weight_value : shopStyles.weight_value} col-3 mb-0 c-pointer px-1 px-xl-1 py-1 flex-grow-1`} key={Weight.Weight} onClick={() => setSelectedWeight(Weight.Weight)}>{Weight.Weight}gm</p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="gray_box px-2 py-3 mt-3">
              <div className={shopStyles.fit_box}>
                <p className={`${shopStyles.filter_title} mb-1`}>Filter by Price</p>
                <div className="horizontal_line mb-3" />
              </div>
              <MultiRangeSlider
                min={3500}
                max={1000000}
                step={50}
                minValue={minValue}
                maxValue={maxValue}
                onChange={({ minValue, maxValue }) => {
                  handleRangeChange(minValue, maxValue);
                }}
                label={false}
                ruler={false}
                barLeftColor="#FCC65B80"
                barInnerColor="#FCC65B"
                barRightColor="#FCC65B80"
                thumbLeftColor="#FCC65B"
                thumbRightColor="#FCC65B"
                className={shopStyles.range_filter}
              />
            </div>
            <div className="gray_box px-2 py-3 mt-3">
              <div className={shopStyles.fit_box}>
                <p className={`${shopStyles.filter_title} mb-1`}>Filter by Karat</p>
                <div className="horizontal_line mb-3" />
              </div>
              {filteringData?.karats?.map(karat => (
                <div className="d-flex align-items-center justify-content-between pe-md-4 pe-xlg-5 mb-2" key={karat.Karat}>
                  <div className="d-flex align-items-center">
                    <div onClick={() => setSelectedKarat(karat.Karat)} className="c-pointer">
                      {selectedKarat === karat.Karat ? (
                        <img src={checkedBox} alt="checked box" />
                      ) : (
                        <img src={checkBox} alt="check box" />
                      )}
                    </div>
                    <p className={`${shopStyles.checkbox_txt2} ms-2 mb-0 fw-medium`}>{karat.Karat}k</p>
                  </div>
                  <p className={`${shopStyles.karat_items} mb-0`}>{karat.count} items</p>
                </div>
              ))}
            </div>
            <div className="gray_box px-3 py-3 mt-3">
              <div className={shopStyles.fit_box}>
                <p className={`${shopStyles.filter_title} mb-1`}>Filter by Categories</p>
                <div className="horizontal_line mb-3" />
              </div>
              {categories?.map((cat) => (
                <div className="d-flex align-items-center mt-4" key={cat.id}>
                  <div onClick={() => setSelectedCat(cat.name)} className="c-pointer">
                    {selectedCat === cat.name ? (
                      <img src={checkedBox} alt="checked box" />
                    ) : (
                      <img src={checkBox} alt="check box" />
                    )}
                  </div>
                  <p className={`${shopStyles.checkbox_txt2} ms-2 mb-0`}>{cat.name}</p>
                </div>
              ))}
            </div>
            {isFilterOpen && (
              <Button title={'view products'} style={`w-100 py-2 mt-3`} />
            )}
          </div>
          <div className="col-12 col-md-7 col-lg-8 flex-md-grow-1">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <p className={`${shopStyles.all_txt} mb-0`}>showing all {searchOrFilterData ? searchOrFilterData?.length : allProducts?.length} results</p>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center me-3 d-md-none" onClick={() => setIsFilterOpen(true)}>
                  <img src={filter} alt="filter icon" className={shopStyles.filter_icon} />
                  <p className={`${shopStyles.filter_txt} mb-0 ms-1`}>filter</p>
                </div>
              </div>
            </div>
            <div className="horizontal_line" />
            <div className="row-gap-4 column-gap-2 row flex-wrap my-4 px-3 px-md-4">
              {
                searchOrFilterData ? searchOrFilterData?.map((item) => <ProductItem item={item} key={item.id} style={`col-5 col-lg-3 flex-grow-1`} />)
                  : allProducts?.map((item) => <ProductItem item={item} key={item.id} style={`col-5 col-lg-3 flex-grow-1`} />)
              }
            </div>
          </div>
        </div>
        {/* may_like */}
        <section className={cartStyles.may_like}>
          <div className="container">
            <p className={cartStyles.may_title}>you may like</p>
            <div className={`${cartStyles.likes_box} d-flex mt-0`} onWheel={(event) => handleWheel(event, likesRef)} ref={likesRef}>
              {LIKESDATA.map((item) => <MayLikeItem item={item} />)}
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default Shop;
