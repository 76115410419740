import React, { useEffect } from "react";
import tradingStyles from "./styles.module.css";
import GPStyles from "../GoldPrice/styles.module.css";
import { AppBanner } from "../../components";
import { Line } from "react-chartjs-2";
import { CategoryScale, Chart } from "chart.js";
import { tradeBanner, info } from "../../assets";
import { useDispatch} from "react-redux";
import { getGoldPrice } from "../../redux/slices/productsSlice";
import BalanceSection from "./BalanceSection";

Chart.register(CategoryScale);

function GoldPriceHistory() {
  const dispatch = useDispatch();
  // const { localGoldPrice } = useSelector((state) => state.products);
  useEffect(() => {
    dispatch(getGoldPrice());
  }, [dispatch]);
  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Nov",
    "Dec",
  ];
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "",
        backgroundColor: "#FCC65B80",
        borderColor: "#A7833F",
        data: [2020, 2030, 2020, 2060, 2040, 2050],
        fill: false,
      },
    ],
  };

  return (
    <main className="bg_dark">
      <div className="mt-lg-4">
        <AppBanner url={tradeBanner} title={"gold price"} />
      </div>
      <div>
        {/* <BalanceSection />*/}
        
      </div>
      {/* charts_section */}
      <section className={`${tradingStyles.charts_section} pt-5 pb-1`}>
        <div className="container">
          <div className={GPStyles.prices_title_box}>
            <h3 className={GPStyles.prices_title}>
              international gold price <span className="span">per</span> ounce
            </h3>
          </div>
          <div
            className={`${GPStyles.charts_container} ${tradingStyles.charts_container}`}
          >
            <div
              className={`${GPStyles.chart_upper} d-flex justify-content-between align-items-center`}
            >
              <div>
                <p className={GPStyles.charts_txt}>
                  gold price <span className="span">per</span> ounce
                </p>
                <p className={GPStyles.charts_title}>
                  international gold price chart
                </p>
              </div>
              <div className={GPStyles.charts_icon_box}>
                <img src={info} alt="help info" className="img" />
              </div>
            </div>
            <div className={`${GPStyles.line_chart_box}`}>
              <Line
                data={chartData}
                className={GPStyles.line_chart}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        color: "#FCC65B50",
                        tickBorderDash: [0.01, 0.01],
                      },
                      ticks: {
                        color: "#FCC65B80",
                      },
                    },
                    y: {
                      grid: {
                        color: "#FCC65B50",
                        tickBorderDash: [0.01, 0.01],
                      },
                      ticks: {
                        color: "#FCC65B",
                        callback: function (value) {
                          return value;
                        },
                      },
                      min: 2010,
                      max: 2070,
                    },
                  },
                }}
              />
            </div>
            <div className="pt-3">
              <p className={GPStyles.charts_title}>
                Local Gold Price Today in Egypt:
                <span className="fw-bold"> 2020</span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default GoldPriceHistory;
