import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddMoneyWallet } from "../../../redux/slices/productsSlice";
import { add, balanceCard } from "../../../assets";
import { Button, InputField } from "../../../components";
import checkStyles from "./styles.module.css";
import Modal from "react-modal";
import { getUserDetails } from "../../../redux/slices/authSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const modal_style = {
  content: {
    width: "40%",
    height: "80%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#1D1D1D",
    padding: "2% 4%",
    zIndex: 20,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)", // Darker overlay
    zIndex: 10, // Ensure this value is lower than the modal
  },
};

function BalanceSection() {
  const { userInfo, user, token } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.products);
  const [totalFees, setTotalFees] = useState(0);
  const [addMoneyModalIsOpen, setAddMoneyModalIsOpen] = useState(false);
  const [instaAndBankModalIsOpen, setInstaAndBankModalIsOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [transferData, setTransferData] = useState({
    payment_method: "",
    fees: "",
    receipt_image: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Calculate total fees whenever userInfo changes
  useEffect(() => {
    console.log('userInfo', userInfo);
    if (userInfo && userInfo.wallets) {
      // Calculate the total fees for wallets where verified === 1
      setTotalFees(userInfo?.wallets[0]?.wallet_total)
    }
  }, [userInfo]);

  //================ Get User Data ====================
  useEffect(() => {
    dispatch(getUserDetails(user && user.id));
  }, [dispatch]);

  const sweetalertHandler = () => {
    Swal.fire({
      title: 'Unauthenticated.',
      // showConfirmButton: true,
      confirmButtonText: 'Login',
      preConfirm: () => { navigate("/login") },
      customClass: {
        popup: "my_popup",
        title: "my_title",
        confirmButton: "my_content",
      },
      showClass: {
        popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                `,
      },
      hideClass: {
        popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
                `,
      },
    });
  };

  const addMoneyHandler = () => {
    token ? setAddMoneyModalIsOpen(true) : sweetalertHandler();
  };

  //============== Close Modal Handler Functions ==============
  const closeAddMoneyModal = () => {
    setAddMoneyModalIsOpen(false);
    setTransferData({ payment_method: "" });
  };

  const closeInstaAndBankModal = () => {
    setInstaAndBankModalIsOpen(false);
    setTransferData({ fees: "", receipt_image: null, payment_method: '' });
    setPreviewImage(null);
  };

  // Choose Instapay or Bank Transfeer
  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    // Update transferData with the selected payment method
    setTransferData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setInstaAndBankModalIsOpen(true)
    setAddMoneyModalIsOpen(false);
  };

  // Add Your Fees
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTransferData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Add your receipt_image
  const handleFileInputChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreviewImage(fileUrl);
      setTransferData((prevState) => ({
        ...prevState,
        [name]: file,
      }));
    }
  };

  const sendMoneyHandler = async (e) => {
    e.preventDefault();
    if (
      transferData.receipt_image !== null &&
      transferData.fees !== "" &&
      transferData.payment_method !== ""
    ) {
      const formData = new FormData();
      formData.append("receipt_image", transferData.receipt_image); // This is the file
      formData.append("fees", transferData.fees);
      formData.append("payment_method", transferData.payment_method);

      // Dispatch the AddMoneyWallet action
      dispatch(AddMoneyWallet(formData))
        .unwrap()
        .then(() => {
          setTransferData({ payment_method: "", fees: "", receipt_image: null }); // Clear data
          setPreviewImage(null);
          notify('Wait verify this process..')
          setInstaAndBankModalIsOpen(false);
        })
    } else {
      notify('deposit image and amount are required.')
    }
  };

  const notify = (text) => {
    toast(
      <div className={`${checkStyles.Toastify__container} d-flex flex-column`}>
        <p className={checkStyles.toast_message}>{text}</p>
      </div>,
      {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: true,
        rtl: false,
        theme: "dark",
        className: `${checkStyles.Toastify__toast} pt-3 pb-0`,
        closeButton: false,
      }
    );
  };

  return (
    <div>
      {/* balance_section */}
      <section className={`${checkStyles.balance_section} py-4 my-5 mt-lg-0`}>
        <div className="container">
          <div className="row justify-content-center">
            <div
              className={`${checkStyles.card_container} col-12 col-md-5 col-lg-4 me-md-4 d-flex justify-content-center align-items-center`}
              style={{ backgroundImage: `url(${balanceCard})` }}
            >
              <div className={checkStyles.balance_content}>
                <p className={`${checkStyles.balance_txt} mb-1`}>
                  Your current Balance
                </p>
                <p className={`${checkStyles.balance_price} mb-0`}>
                  {user ? totalFees : "0"} EGP
                </p>
              </div>
            </div>
            <div
              className="col-12 col-md-3 d-flex align-items-center justify-content-center mt-4 mt-md-0 c-pointer"
              onClick={addMoneyHandler}
            >
              <div className={checkStyles.add_box}>
                <img src={add} alt="balance card" className="img" />
              </div>
              <p className={`${checkStyles.add_txt} mb-0 ms-2`}>add money</p>
            </div>
          </div>
        </div>
      </section>
      {/* Instapay or BankTransfer Modal */}
      <Modal
        isOpen={addMoneyModalIsOpen}
        onRequestClose={closeAddMoneyModal}
        style={modal_style}
        contentLabel="Add Money Modal"
        ariaHideApp={false}
      >
        <h5 className={`${checkStyles.modal_title} mb-4`}>
          Select Payment Method
        </h5>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-start justify-content-between mb-3">
            <div>
              <p className={`${checkStyles.payment_title} mb-1`}>Instapay</p>
              <p className={`${checkStyles.payment_txt} mb-0`}>No Fees</p>
            </div>
            <div className={checkStyles.plus_img_box}>
              <img
                src="https://play-lh.googleusercontent.com/Pv1p8v-KJq4Z_LLOjQjBZpK8DNJg4Zb5aNOQY7WfoiHR4Gfs165Z1TTNMThZ883Yfg"
                alt="Instapay logo"
                className="img"
              />
            </div>
            <input
              type="radio"
              id="Instapay"
              className={checkStyles.radio_input}
              name="payment_method" // Use a consistent name
              value="Instapay"
              checked={transferData.payment_method === "Instapay"}
              onChange={handleRadioChange}
            />
            <label htmlFor="Instapay" className={checkStyles.radio_btn} />
          </div>
          <div className="d-flex align-items-start justify-content-between">
            <div>
              <p className={`${checkStyles.payment_title} mb-1`}>
                Bank Transfer
              </p>
              <p className={`${checkStyles.payment_txt} mb-0`}>No Fees</p>
            </div>
            <div className={checkStyles.plus_img_box}>
              <img
                src="https://c8.alamy.com/comp/2BWR0D2/bank-transfer-icon-simple-element-from-banking-collection-creative-bank-transfer-icon-for-web-design-templates-infographics-and-more-2BWR0D2.jpg"
                alt="Bank Transfer logo"
                className="img"
              />
            </div>
            <input
              type="radio"
              id="Bank Transfer"
              className={checkStyles.radio_input}
              name="payment_method" // Use a consistent name
              value="Bank Transfer"
              checked={transferData.payment_method === "Bank Transfer"}
              onChange={handleRadioChange}
            />
            <label htmlFor="Bank Transfer" className={checkStyles.radio_btn} />
          </div>
        </div>
      </Modal>
      {/* Instapay  Modal */}
      <Modal
        isOpen={instaAndBankModalIsOpen}
        onRequestClose={closeInstaAndBankModal}
        style={modal_style}
        contentLabel="Instapay and Bank Modal"
        ariaHideApp={false}
      >
        <h5 className={`${checkStyles.modal_title} mb-4`}>{transferData.payment_method}</h5>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>Name</p>
          <p className={`${checkStyles.payment_txt} mb-0`}>
            Trejars For Gold & Precious Metals Trading
          </p>
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>Address</p>
          <p className={`${checkStyles.payment_txt} mb-0 w-75`}>
            Unit 3, Building 7, Second floor, Lejanda village, Second district
          </p>
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>Bank Name</p>
          <p className={`${checkStyles.payment_txt} mb-0`}>Banque Misr</p>
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>Account Number</p>
          <p className={`${checkStyles.payment_txt} mb-0`}>5120001000017578</p>
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>IBAN</p>
          <p className={`${checkStyles.payment_txt} mb-0`}>
            EG190002051205120001000017578
          </p>
        </div>
        <div className="d-flex align-items-start justify-content-between mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>SWIFT CODE</p>
          <p className={`${checkStyles.payment_txt} mb-0`}>BMISEGCXXXX</p>
        </div>
        <div className="horizontal_line" />
        <div className="d-flex align-items-start justify-content-between mt-3 mb-3">
          <p className={`${checkStyles.payment_title} mb-0`}>
            Deposited Amount
          </p>
          <InputField
            type={"text"}
            fieldName={"fees"}
            placeholder={"Enter deposited price"}
            style={checkStyles.field_box}
            inputStyle={checkStyles.input}
            value={transferData.fees}
            onChange={handleInputChange}
          />
        </div>
        <div className="d-flex align-items-start justify-content-between mb-0 row">
          <div>
            <p className={`${checkStyles.payment_title} mb-0`}>Receipt Image</p>
            <InputField
              type={"file"}
              fieldName={"receipt_image"}
              placeholder={"No image selected"}
              value={transferData.receipt_image?.name}
              onChange={handleFileInputChange}
            />
          </div>

          {previewImage && (
            <div className="my-1 text-center">
              <img
                src={previewImage}
                alt="Receipt Preview"
                className="img-fluid"
                style={{ maxHeight: "200px", objectFit: "cover" }}
              />
            </div>
          )}
        </div>
        {transferData.payment_method === "Instapay" && (
          <p className={`${checkStyles.working_days} mb-0 mb-4`}>
            Deposits may take up to 2 Working days to be confirmed
          </p>
        )}
        <div className="d-flex justify-content-center">
          <Button
            title={"Done"}
            onClick={sendMoneyHandler}
            style={`w-50 py-1`}
            loading={loading}
          />
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default BalanceSection;
