import React, { useState } from "react";
import homeStyles from "../Home/styles.module.css";
import checkStyles from '../Checkout/styles.module.css'
import { AppBanner, Button, InputField } from "../../components";
import {
  call,
  contactBanner,
  facebook,
  instagram,
  linkedin,
  location,
  mail,
  twitter,
  youtube,
} from "../../assets";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sendContactMessage } from "../../redux/slices/contantUsSlice";
import { toast, ToastContainer } from "react-toastify";

function ContactUs() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.contact);
  const [contactData, setContactData] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    company_name: '',
    message: '',
  });

  const notify = (text) => {
    toast(
      <div className={`${checkStyles.Toastify__container} d-flex flex-column`}>
        <p className={checkStyles.toast_message}>{text}</p>
      </div>,
      {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: true,
        rtl: false,
        theme: "dark",
        className: `${checkStyles.Toastify__toast} pt-3 pb-0`,
        closeButton: false,
      }
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setContactData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{11}$/;

    if (
      contactData.fname.trim() === '' ||
      contactData.lname.trim() === '' ||
      contactData.email.trim() === '' ||
      contactData.phone.trim() === '' ||
      contactData.company_name.trim() === '' ||
      contactData.message.trim() === ''
    ) {
      notify('All fields are reqired.')
      return false;
    }

    if (!emailRegex.test(contactData.email)) {
      notify('The email must be a valid email address.')
      return false;
    }

    if (!phoneRegex.test(contactData.phone)) {
      notify('The phone must be at least 11 characters.')
      return false;
    }

    return true;
  };

  const submitContact = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      Object.keys(contactData).forEach(key => {
        formData.append(key, contactData[key])
      })
      dispatch(sendContactMessage(formData))
        .unwrap()
        .then(() => {
          window.scrollTo(0, 0);
          notify('Message sent successfully!')
          setContactData({
            fname: '',
            lname: '',
            email: '',
            phone: '',
            company_name: '',
            message: '',
          })
        })
        .catch(err => {
          console.log('contact err', err);
        })
    }
  }

  return (
    <main>
      <div className="mt-lg-4 mb-5">
        <AppBanner page={3} title={"contact us"} url={contactBanner} />
      </div>
      <section className={homeStyles.contact}>
        <div className="container">
          <div className={`row ${homeStyles.contact_gap}`}>
            <div
              className={`${homeStyles.contact_box} col-12 col-md-5 flex-md-grow-1 order-last order-md-first`}
            >
              <p className={homeStyles.contact_us_title}>contact with uS</p>
              <p className={homeStyles.contact_us_txt}>
                Please feel free to contact us regarding any concerns or
                inquiries.
              </p>
              <form>
                <div
                  className={`d-flex flex-column flex-lg-row ${homeStyles.inputs_box}`}
                >
                  <InputField
                    type={"text"}
                    fieldName={"fname"}
                    label={"first name"}
                    placeholder={"Enter your first name"}
                    style={homeStyles.half_input}
                    inputStyle={`${homeStyles.contact_input} col-12`}
                    value={contactData.fname}
                    onChange={handleInputChange}
                  />
                  <InputField
                    type={"text"}
                    fieldName={"lname"}
                    label={"last name"}
                    placeholder={"Enter your last name"}
                    style={homeStyles.half_input}
                    inputStyle={`${homeStyles.contact_input} col-12`}
                    value={contactData.lname}
                    onChange={handleInputChange}
                  />
                </div>
                <InputField
                  type={"email"}
                  fieldName={"email"}
                  label={"email"}
                  placeholder={"Enter your email"}
                  style={homeStyles.contact_field}
                  inputStyle={homeStyles.contact_input}
                  value={contactData.email}
                  onChange={handleInputChange}
                />
                <InputField
                  type={"text"}
                  fieldName={"phone"}
                  label={"phone number"}
                  placeholder={"Enter your phone number"}
                  style={homeStyles.contact_field}
                  inputStyle={homeStyles.contact_input}
                  value={contactData.phone}
                  onChange={handleInputChange}
                />
                <InputField
                  type={"text"}
                  fieldName={"company_name"}
                  label={"company name"}
                  placeholder={"Enter your company name"}
                  style={homeStyles.contact_field}
                  inputStyle={homeStyles.contact_input}
                  value={contactData.company_name}
                  onChange={handleInputChange}
                />
                <InputField
                  isTextarea={true}
                  rows={8}
                  cols={2}
                  fieldName={"message"}
                  label={"message"}
                  placeholder={"Enter your message"}
                  style={homeStyles.contact_field}
                  inputStyle={homeStyles.contact_input}
                  value={contactData.message}
                  onChange={handleInputChange}
                />
                <Button
                  title={"send"}
                  style={homeStyles.contact_btn}
                  onClick={submitContact}
                  loading={loading}
                />
              </form>
            </div>
            <div
              className={`${homeStyles.contact_box} col-12 col-md-5 flex-md-grow-1 order-first order-md-last`}
            >
              <p className={homeStyles.contact_info_title}>
                contact information
              </p>
              <p className={homeStyles.contact_info_txt}>
                Have a question or a message for us? we're here to help! Feel
                free to share your thoughts, inquiries, or feedback in the form
                below. we'll get back to you as soon as possible. Your input is
                important to us!
              </p>
              <div className={`${homeStyles.contact_boxes} mb-3 mb-lg-4`}>
                <div className={homeStyles.contact_boxes_img}>
                  <img src={mail} alt="mail" className="img" />
                </div>
                <div>
                  <p className={homeStyles.contact_boxes_title}>Chat with us</p>
                  <p className={homeStyles.contact_boxes_txt}>
                    Reach out to us at help@trejars.com or trejarsadmn@gmail.com
                  </p>
                </div>
              </div>
              <div className={`${homeStyles.contact_boxes} mb-3 mb-lg-4`}>
                <div className={homeStyles.contact_boxes_img}>
                  <img src={location} alt="location" className="img" />
                </div>
                <div>
                  <p className={homeStyles.contact_boxes_title}>Visit us</p>
                  <p className={homeStyles.contact_boxes_txt}>
                    Second District, Building 7 , Lijanda Village , Sheikh Zayed
                    City, Giza Egypt
                  </p>
                </div>
              </div>
              <div className={`${homeStyles.contact_boxes} mb-3 mb-lg-4`}>
                <div className={homeStyles.contact_boxes_img}>
                  <img src={call} alt="call" className="img" />
                </div>
                <div>
                  <p className={homeStyles.contact_boxes_title}>Call us</p>
                  <p className={homeStyles.contact_boxes_txt}>
                    Ring US on{" "}
                    <Link
                      className={`text-decoration-none ${homeStyles.contact_boxes_txt}`}
                      to="tel:+201027815611"
                    >
                      +201027815611
                    </Link>
                  </p>
                </div>
              </div>
              <div className={homeStyles.contact_social_icons}>
                <div className={homeStyles.contact_icon_box}>
                  <img src={linkedin} alt="linkedin" className="img" />
                </div>
                <div className={homeStyles.contact_icon_box}>
                  <img src={twitter} alt="twitter" className="img" />
                </div>
                <div className={homeStyles.contact_icon_box}>
                  <img src={instagram} alt="instagram" className="img" />
                </div>
                <div className={homeStyles.contact_icon_box}>
                  <img src={facebook} alt="facebook" className="img" />
                </div>
                <div className={homeStyles.contact_icon_box}>
                  <img src={youtube} alt="youtube" className="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </main>
  );
}

export default ContactUs;
