import React, { useEffect, useRef, useState } from "react";
import homeStyles from './styles.module.css'
import checkStyles from '../Checkout/styles.module.css'
import {
  appStore,
  arrowSquareLeft,
  arrowSquareRight,
  btcGallery,
  call,
  cartGallery,
  coin,
  coins,
  earring,
  facebook,
  goldBar,
  goldBars,
  googlePlay,
  headerImg,
  instagram,
  linkedin,
  location,
  lockClock,
  mail,
  navLogo,
  neckless,
  service1,
  service2,
  service3,
  service4,
  silverBar,
  twitter,
  wearables,
  youtube
} from "../../assets";
import { Button, GallaryItem, InputField, NewsItem, ProductItem, TrendItem } from "../../components";
import { scroll } from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { GetAllProducts } from "../../redux/slices/productsSlice";
import { toast, ToastContainer } from "react-toastify";
import { sendContactMessage } from "../../redux/slices/contantUsSlice";

const TRENDS = [
  { id: 1, url: goldBar, title: 'gold bars', products: '16 products' },
  { id: 2, url: silverBar, title: 'silver bars', products: '18 products' },
  { id: 3, url: coin, title: 'coins', products: '11 products' },
  { id: 4, url: wearables, title: 'wearables', products: '19 products' }
]

const GALLERY = [
  { id: 1, url: coins },
  { id: 2, icon: lockClock, title: 'secure payment', text: '100% Secure Online Payment' },
  { id: 3, url: goldBars },
  { id: 4, icon: cartGallery, title: 'secure store', text: 'Your products are safe with us' },
  { id: 5, url: neckless },
  { id: 6, icon: btcGallery, title: 'best quality', text: 'Original Products Guarenteed' },
]

const NEWS = [
  { id: 1, icon: navLogo, title: 'Gold/usd.', Price: 2029.47, percentage: '+6.63(+0.5%)', color: 'green' },
  { id: 2, icon: navLogo, title: 'Gold/24k.', Price: 2029.47, percentage: '0.00(0.0%)', color: '#A7833F' },
  { id: 3, icon: navLogo, title: 'Silver/usd.', Price: 22.15, percentage: '-6.63(-0.5%)', color: 'red' },
]

function Home() {
  const [contactData, setContactData] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    company_name: '',
    message: '',
  });

  const dispatch = useDispatch();
  const productsRef = useRef(null);
  const intervalId = useRef(null);

  const notify = (text) => {
    toast(
      <div className={`${checkStyles.Toastify__container} d-flex flex-column`}>
        <p className={checkStyles.toast_message}>{text}</p>
      </div>,
      {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: true,
        rtl: false,
        theme: "dark",
        className: `${checkStyles.Toastify__toast} pt-3 pb-0`,
        closeButton: false,
      }
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setContactData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{11}$/;

    if (
      contactData.fname.trim() === '' ||
      contactData.lname.trim() === '' ||
      contactData.email.trim() === '' ||
      contactData.phone.trim() === '' ||
      contactData.company_name.trim() === '' ||
      contactData.message.trim() === ''
    ) {
      notify('All fields are reqired.')
      return false;
    }

    if (!emailRegex.test(contactData.email)) {
      notify('The email must be a valid email address.')
      return false;
    }

    if (!phoneRegex.test(contactData.phone)) {
      notify('The phone must be at least 11 characters.')
      return false;
    }

    return true;
  };

  const submitContact = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      Object.keys(contactData).forEach(key => {
        formData.append(key, contactData[key])
      })
      dispatch(sendContactMessage(formData))
        .unwrap()
        .then(() => {
          window.scrollTo(0, 0);
          notify('Message sent successfully!')
          setContactData({
            fname: '',
            lname: '',
            email: '',
            phone: '',
            company_name: '',
            message: '',
          })
        })
        .catch(err => {
          console.log('contact err', err);
        })
    }
  }

  useEffect(() => {
    dispatch(GetAllProducts());
    const reFetchData = () => {
      dispatch(GetAllProducts());
    };
    intervalId.current = setInterval(reFetchData, 30000);
    return () => clearInterval(intervalId.current);
  }, []);

  const { loading, allProducts } = useSelector(state => state.products);

  return (
    <div className={homeStyles.bg_dark}>
      <div className={homeStyles.prices_bar}>
        {/* {NEWS.map((item) => <NewsItem item={item} isLastItem={NEWS.length - 1 ? true : false} />)} */}
      </div>
      <main>
        {/* header */}
        <header className={`${homeStyles.header} mb-5 order-5`}>
          <div className="container">
            <div className="row align-content-center pt-5">
              <div className="col-12 col-md-5 flex-grow-1 mb-4 mb-lg-0">
                <figure className={`${homeStyles.header_img_box}`}>
                  <img src={headerImg} alt="phone" className={homeStyles.img} />
                </figure>
              </div>
              <div className={"col-12 col-md-5 flex-grow-1 g-1 pb-4"}>
                <h1 className={homeStyles.header_title}>
                  Unleash the Power of Gold Investment
                </h1>
                <p className={homeStyles.header_txt}>Maximize opportunities, ensure future stability with our platform for intelligent gold and investment, your gateway to financial security.</p>
                <Button title={'get start for free'} to='/goldPriceHistory' style={homeStyles.primary_btn} />
                <div className={homeStyles.horizontal_line} />
                <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-md-between align-items-center">
                  <p className={`${homeStyles.availability_txt} col-12 col-lg-4 mb-4 mb-lg-0`}>App available on</p>
                  <div className="d-flex align-items-center justify-content-center col-12 col-lg-7">
                    <div className={`${homeStyles.availability_img_box} me-3 flex-grow-1`}>
                      <img src={appStore} alt="app-store" className={homeStyles.img} />
                    </div>
                    <div className={`${homeStyles.availability_img_box} flex-grow-1`}>
                      <img src={googlePlay} alt="google-play" className={homeStyles.img} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* services */}
        <section className={`${homeStyles.services} mb-5`}>
          <div className="container">
            <div className="row align-items-center justify-content-between gx-5 gx-md-2 gx-lg-4 align-items-stretch">
              <div className='col-6 col-sm-6 col-md-3 d-flex flex-column align-items-center justify-content-start py-4'>
                <div className={homeStyles.service_img_box}>
                  <img src={service1} alt="price range" className='img' />
                </div>
                <p className={homeStyles.service_title}>Save your money value</p>
              </div>
              <div className='col-6 col-sm-6 col-md-3 d-flex flex-column align-items-center justify-content-start py-4'>
                <div className={homeStyles.service_img_box}>
                  <img src={service2} alt="checked" className='img' />
                </div>
                <p className={homeStyles.service_title}>All bullion sizes are available</p>
              </div>
              <div className='col-6 col-sm-6 col-md-3 d-flex flex-column align-items-center justify-content-start py-4'>
                <div className={homeStyles.service_img_box}>
                  <img src={service3} alt="reload repeat" className='img' />
                </div>
                <p className={homeStyles.service_title}>Prices updated continuously</p>
              </div>
              <div className='col-6 col-sm-6 col-md-3 d-flex flex-column align-items-center justify-content-start py-4'>
                <div className={homeStyles.service_img_box}>
                  <img src={service4} alt="shop cart" className='img' />
                </div>
                <p className={homeStyles.service_title}>Shop our gold bullion</p>
              </div>
            </div >
          </div >
        </section >
        {/* designs */}
        <section className={`${homeStyles.designs} mb-5`}>
          <div className={`${homeStyles.designs_content_container} py-4 order-2 order-md-1`}>
            <div className={homeStyles.designs_content}>
              <h2 className={homeStyles.designs_title}>Hassle free investment in Precious metals</h2>
              <p className={homeStyles.designs_txt}>Precious metals such as Gold & Silver is real money, Everything else is credit.</p>
              <Button title={'shop now'} to='/shop' style={homeStyles.primary_btn} />
            </div>
          </div>
          <div className={`${homeStyles.designs_img_box} order-1 order-md-2`}>
            <img src={earring} alt="woman earrings trejars" className='img' />
          </div>
        </section >
        {/* trends */}
        <section className={`${homeStyles.trends} mb-5`} >
          <div className="container">
            <div className={homeStyles.trends_title_box}>
              <h3 className={homeStyles.trends_title}>
                shop by trend categories
              </h3>
            </div>
            <div className='row justify-content-center justify-sm-content-between align-items-center gap-4 px-2 px-sm-0'>
              {TRENDS.map((trend) => <TrendItem item={trend} key={trend.id} />)}
            </div>
          </div>
        </section >
        {/* gallery */}
        <section className='mb-5'>
          <div className="container">
            <div className="row justify-content-sm-between justify-content-center">
              {GALLERY.map((item) => <GallaryItem item={item} isImg={item.url ? true : false} key={item?.id} styles={'col-12 col-sm-5 col-lg-3 p-0 m-2 m-md-3 flex-grow-1'} />)}
            </div>
          </div>
        </section >
        {/* products */}
        <section className={`${homeStyles.products} mb-5`}>
          <div className="container">
            <div className={homeStyles.trends_title_box}>
              <h3 className={`${homeStyles.trends_title} mb-1`}>products selection</h3>
            </div>
            <div className={`${homeStyles.products_box} d-flex`} ref={productsRef}>
              {allProducts?.map((item) => <ProductItem item={item} key={item.id} />)}
            </div>
            <div className={homeStyles.products_btns_box}>
              <div onClick={() => scroll(-70, productsRef)} className={homeStyles.products_direction}
              ><img src={arrowSquareLeft} alt="arrow left" className="img" />
              </div>
              <div onClick={() => scroll(70, productsRef)} className={homeStyles.products_direction}>
                <img src={arrowSquareRight} alt="arrow right" className="img" />
              </div>
            </div>
          </div>
        </section >
        {/* contact */}
        <section className={homeStyles.contact} >
          <div className="container">
            <div className={`row ${homeStyles.contact_gap}`}>
              <div className={`${homeStyles.contact_box} col-12 col-md-5 flex-md-grow-1 order-last order-md-first`}>
                <p className={homeStyles.contact_us_title}>contact with uS</p>
                <p className={homeStyles.contact_us_txt}>Please feel free to contact us regarding any concerns or inquiries.</p>
                <div className={`d-flex flex-column flex-lg-row ${homeStyles.inputs_box}`}>
                  <InputField
                    type={'text'}
                    fieldName={'fname'}
                    label={'first name'}
                    placeholder={'Enter your first name'}
                    style={homeStyles.half_input}
                    inputStyle={`${homeStyles.contact_input} col-12`}
                    value={contactData.fname}
                    onChange={handleInputChange}
                  />
                  <InputField
                    type={'text'}
                    fieldName={'lname'}
                    label={'last name'}
                    placeholder={'Enter your last name'}
                    style={homeStyles.half_input}
                    inputStyle={`${homeStyles.contact_input} col-12`}
                    value={contactData.lname}
                    onChange={handleInputChange}
                  />
                </div>
                <InputField
                  type={'email'}
                  fieldName={'email'}
                  label={'email'}
                  placeholder={'Enter your email'}
                  style={homeStyles.contact_field}
                  inputStyle={homeStyles.contact_input}
                  value={contactData.email}
                  onChange={handleInputChange}
                />
                <InputField
                  type={'text'}
                  fieldName={'phone'}
                  label={'phone number'}
                  placeholder={'Enter your phone number'}
                  style={homeStyles.contact_field}
                  inputStyle={homeStyles.contact_input}
                  value={contactData.phone}
                  onChange={handleInputChange}
                />
                <InputField
                  type={'text'}
                  fieldName={'company_name'}
                  label={'company name'}
                  placeholder={'Enter your companye Name'}
                  style={homeStyles.contact_field}
                  inputStyle={homeStyles.contact_input}
                  value={contactData.company_name}
                  onChange={handleInputChange}
                />
                <InputField
                  isTextarea={true}
                  rows={8}
                  cols={2}
                  fieldName={'message'}
                  label={'message'}
                  placeholder={'Enter your message'}
                  style={homeStyles.contact_field}
                  inputStyle={homeStyles.contact_input}
                  value={contactData.message}
                  onChange={handleInputChange}
                />
                <Button title={'send'} style={homeStyles.contact_btn} onClick={submitContact} />
              </div>
              <div className={`${homeStyles.contact_box} col-12 col-md-5 flex-md-grow-1 order-first order-md-last`}>
                <p className={homeStyles.contact_info_title}>contact information</p>
                <p className={homeStyles.contact_info_txt}>Have a question or a message for us? we're here to help! Feel free to share your thoughts, inquiries, or feedback in the form below. we'll get back to you as soon as possible. Your input is important to us!
                </p>
                <div className={`${homeStyles.contact_boxes} mb-3 mb-lg-4`}>
                  <div className={homeStyles.contact_boxes_img}>
                    <img src={mail} alt='mail' className='img' />
                  </div>
                  <div>
                    <p className={homeStyles.contact_boxes_title}>Chat with us</p>
                    <p className={homeStyles.contact_boxes_txt}>Reach out to us at help@trejars.com or trejarsadmn@gmail.com</p>
                  </div>
                </div>
                <div className={`${homeStyles.contact_boxes} mb-3 mb-lg-4`}>
                  <div className={homeStyles.contact_boxes_img}>
                    <img src={location} alt='location' className='img' />
                  </div>
                  <div>
                    <p className={homeStyles.contact_boxes_title}>Visit us</p>
                    <p className={homeStyles.contact_boxes_txt}>Second District, Building 7 , Lijanda Village , Sheikh Zayed City, Giza Egypt</p>
                  </div>
                </div>
                <div className={`${homeStyles.contact_boxes} mb-3 mb-lg-4`}>
                  <div className={homeStyles.contact_boxes_img}>
                    <img src={call} alt='call' className='img' />
                  </div>
                  <div>
                    <p className={homeStyles.contact_boxes_title}>Call us</p>
                    <p className={homeStyles.contact_boxes_txt}>Ring US on +201027815611</p>
                  </div>
                </div>
                <div className={homeStyles.contact_social_icons}>
                  <div className={homeStyles.contact_icon_box}>
                    <img src={linkedin} alt="linkedin" className="img" />
                  </div>
                  <div className={homeStyles.contact_icon_box}>
                    <img src={twitter} alt="twitter" className="img" />
                  </div>
                  <div className={homeStyles.contact_icon_box}>
                    <img src={instagram} alt="instagram" className="img" />
                  </div>
                  <div className={homeStyles.contact_icon_box}>
                    <img src={facebook} alt="facebook" className="img" />
                  </div>
                  <div className={homeStyles.contact_icon_box}>
                    <img src={youtube} alt="youtube" className="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section >
        <ToastContainer />
      </main >
    </div >
  )
}

export default Home;
