import React, { useState } from "react";
import loginStyles from "../Login/styles.module.css";
import { Button, InputField } from "../../components";
import { eye, eyeSlash } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
  const { loading, error, reseetPasswordValidData } = useSelector(
    (state) => state.auth
  );
  // console.log(reseetPasswordValidData && reseetPasswordValidData);
  // console.log(reseetPasswordValidData?.otp);
  // console.log(reseetPasswordValidData?.phone);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [errormessage, setErrormessage] = useState("");
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleInputChange = (event) => {
    setErrormessage("");
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateData = () => {
    const passwordStrengthRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    if (
      !formData?.newPassword?.trim() ||
      !formData?.confirmNewPassword?.trim()
    ) {
      setErrormessage("All fields must be filled.");
      return false;
    }

    if (!passwordStrengthRegex?.test(formData?.newPassword)) {
      setErrormessage(
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long."
      );
      return false;
    }

    if (formData?.newPassword !== formData?.confirmNewPassword) {
      setErrormessage("Passwords do not match.");
      return false;
    }

    console.log(formData);

    setErrormessage("");
    return true;
  };
  const confirmRegister = () => {
    const data = {
      phone: reseetPasswordValidData?.phone,
      otp: reseetPasswordValidData?.otp,
      new_password: formData.newPassword,
      new_password_confirmation: formData.confirmNewPassword,
    };
    if (!validateData()) {
      return;
    }
    dispatch(resetPassword(data))
      .unwrap()
      .then(() => {
        navigate("/login");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <main>
      <div className="container pt-5">
        <div className="row justify-content-center">
          <div
            className={`${loginStyles.login_content} col-11 col-md-7 col-lg-5 ps-4 pe-3 pb-3`}
          >
            <p className={loginStyles.login_txt}>Reset Password</p>

            <InputField
              type={!isPasswordVisible ? "password" : "text"}
              fieldName={"newPassword"}
              label={"set new password *"}
              placeholder={"Enter Your new Password"}
              style={loginStyles.field_box}
              inputStyle={loginStyles.input}
              value={formData.newPassword}
              onChange={handleInputChange}
              rightIcon={
                isPasswordVisible ? (
                  <img src={eye} alt="eye" className="img" />
                ) : (
                  <img src={eyeSlash} alt="eye-slash" className="img" />
                )
              }
              onRightIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
            />
            <InputField
              type={!isConfirmPasswordVisible ? "password" : "text"}
              fieldName={"confirmNewPassword"}
              label={"confirm new password *"}
              placeholder={"Enter confirm new Password"}
              style={loginStyles.field_box}
              inputStyle={loginStyles.input}
              value={formData.confirmNewPassword}
              onChange={handleInputChange}
              rightIcon={
                isConfirmPasswordVisible ? (
                  <img src={eye} alt="eye" className="img" />
                ) : (
                  <img src={eyeSlash} alt="eye-slash" className="img" />
                )
              }
              onRightIconClick={() =>
                setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
              }
            />
            {errormessage && (
              <p
                className={`${loginStyles.rem_txt} ${loginStyles.errorText} ms-2 mb-3`}
              >
                {errormessage}
              </p>
            )}
            {error && (
              <p
                className={`${loginStyles.rem_txt} ${loginStyles.errorText} ms-2 mb-3`}
              >
                {error}
              </p>
            )}
            <Button
              title={"Confirm"}
              style={loginStyles.login_btn}
              onClick={confirmRegister}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default ResetPassword;
