import React, { useEffect } from "react";
import confirmStyles from "./styles.module.css";
import { confirmCircle } from "../../assets";
import { Button, OrderPDF } from "../../components";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { GetOrderDetails } from "../../redux/slices/productsSlice";

function OrderConfirm() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { loading, OrderDetails } = useSelector((state) => state.products);
  const { paymentId } = location.state

  useEffect(() => {
    if (paymentId) {
      dispatch(GetOrderDetails({ payment_id: paymentId }));
    }
  }, [dispatch, paymentId]);

  const downloadPdf = async () => {
    const blob = await pdf(<OrderPDF data={OrderDetails[0]} />).toBlob();
    !loading && saveAs(blob, "sample.pdf");
  };

  console.log(OrderDetails);

  return (
    <main>
      <section>
        <div className="container">
          <div className="d-flex flex-column align-items-center">
            <div className={confirmStyles.img_box}>
              <img src={confirmCircle} alt="confirm checked" className="img" />
            </div>
            <p className={confirmStyles.confirm_title}>order confirmed</p>
            <p className={confirmStyles.confirm_txt}>
              thank you for your order
            </p>
            <p className={`${confirmStyles.confirm_txt} px-md-5`}>
              You will receive a call from us to confirm your order. <br />{" "}
              Additional you will receive email confirmation shortly
            </p>
            <div
              className={`${confirmStyles.gray_box} p-3 col-12 col-md-6 col-lg-5`}
            >
              <div className="d-flex justify-content-between pt-2 pb-4">
                <p className={`${confirmStyles.order_title} mb-0`}>
                  total order
                </p>
                <p className={`${confirmStyles.order_txt} mb-0`}>
                  {OrderDetails[0]?.cart?.reduce((total, item) => total + item.quantity, 0)} items
                </p>
              </div>
              <div className="horizontal_line" />
              <div className="d-flex justify-content-between py-4">
                <p className={`${confirmStyles.order_title} mb-0`}>Order Id</p>
                <p className={`${confirmStyles.order_txt} mb-0`}>
                  {OrderDetails[0]?.order?.id}
                </p>
              </div>
              <div className="horizontal_line" />
              <div className="d-flex justify-content-between py-4">
                <p className={`${confirmStyles.order_title} mb-0`}>
                  Payment Method
                </p>
                <p className={`${confirmStyles.order_txt} mb-0`}>{OrderDetails[0]?.order?.payment_method}</p>
              </div>
              <div className="horizontal_line" />
              <div className="d-flex justify-content-between py-4">
                <p className={`${confirmStyles.order_title} mb-0`}>Status</p>
                <p className={`${confirmStyles.order_txt} mb-0`}>
                  {OrderDetails[0]?.order?.status}
                </p>
              </div>
              <div className="horizontal_line" />
              <div className="d-flex justify-content-between py-4">
                <p className={`${confirmStyles.order_title} mb-0`}>
                  Total Amount
                </p>
                <p className={`${confirmStyles.order_txt} mb-0`}>
                  {OrderDetails[0]?.order?.amount}
                  {OrderDetails[0]?.order?.currency}
                </p>
              </div>
              <div className="horizontal_line" />
              <div className="d-flex flex-column align-items-center">
                <Button
                  title={"download invoice"}
                  style={`py-2 d-block col-12 col-sm-8 col-lg-7 my-3`}
                  onClick={downloadPdf}
                />
                <Button
                  title={"continue shopping"}
                  style={`${confirmStyles.secondry_btn} py-2 d-block col-12 col-sm-8 col-lg-7`}
                  to={"/shop"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default OrderConfirm;
