import React, { useEffect, useState } from "react";
import dashStyles from "../Dashboard/styles.module.css";
import infoStyles from "./styles.module.css";
import { Button, InputField } from "../../components";
import { uploadIcon, avatar } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";

function PersonalInfo({ pageID, activePageID, activeIdHandler }) {
  const [isActive, setIsActive] = useState(false);
  const [isError, setIsError] = useState(false);
  const { user, loading, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    Fname: user?.Fname,
    Lname: user?.Lname,
    email: user?.email,
    phone: user?.phone,
    country: user?.country,
    address: user?.address,
    city: user?.city,
    postcode: user?.postcode,
    photo: user?.photo,
  });
  const navigate = useNavigate();

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Create a local URL for the uploaded image for preview purposes
      const imageUrl = URL.createObjectURL(file);

      setUserData((prevUserData) => ({
        ...prevUserData,
        photo: imageUrl,  // For preview
        photoFile: file   // For sending to the server
      }));
    }
  };

  const handleInputChange = (item, event) => {
    setUserData({ ...userData, [item]: event.target.value });
  };

  const confirmUpdate = () => {
    setIsError(false);
    const formData = new FormData();

    // Append all the other fields from userData
    formData.append("Fname", userData.Fname);
    formData.append("Lname", userData.Lname);
    formData.append("email", userData.email);
    formData.append("phone", userData.phone);
    formData.append("country", userData.country);
    formData.append("address", userData.address);
    formData.append("city", userData.city);
    formData.append("postcode", userData.postcode);

    // Append the actual photo file if it exists
    if (userData.photoFile) {
      formData.append("photo", userData.photoFile); // Send the file to the server
    }

    // Dispatch the action with the FormData
    dispatch(updateProfile(formData))
      .unwrap()
      .then(() => {
        activeIdHandler(1)
      }).catch(() => setIsError(true))
  };

  useEffect(() => {
    if (activePageID === pageID) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [activePageID]);

  const COUNTRIES = ["Egypt"];
  return (
    <div
      className={`${isActive ? dashStyles.active_container : dashStyles.in_active_container
        } col-11 col-md-8 flex-md-grow-1 row`}
    >
      <div className="gray_box col-12 col-lg-7 me-lg-3 flex-grow-1 order-last order-lg-first py-4 pb-lg-5">
        <div className="d-flex justify-content-between mb-3">
          <InputField
            type={"text"}
            style={`${infoStyles.half_input}`}
            inputStyle={`px-2 py-2`}
            label={"first name*"}
            placeholder={"Enter your first name"}
            fieldName={"Fname"}
            value={userData?.Fname}
            onChange={(event) => handleInputChange("Fname", event)}
          />
          <InputField
            type={"text"}
            style={`${infoStyles.half_input}`}
            inputStyle={`px-2 py-2`}
            label={"last name*"}
            placeholder={"Enter your last name"}
            fieldName={"Lname"}
            value={userData?.Lname}
            onChange={(event) => handleInputChange("Lname", event)}
          />
        </div>
        <div className="d-flex justify-content-between mb-3">
          <InputField
            type={"email"}
            style={`${infoStyles.half_input}`}
            inputStyle={`px-2 py-2`}
            label={"email*"}
            placeholder={"Enter your Email"}
            fieldName={"email"}
            value={userData?.email}
            onChange={(event) => handleInputChange("email", event)}
          />
          <InputField
            type={"tel"}
            style={`${infoStyles.half_input}`}
            inputStyle={`px-2 py-2`}
            label={"phone number*"}
            placeholder={"Enter your phone number"}
            fieldName={"phone"}
            value={userData?.phone}
            onChange={(event) => handleInputChange("phone", event)}
          />
        </div>
        <InputField
          list={COUNTRIES}
          isList={true}
          style={`mb-3`}
          inputStyle={`px-2 py-2`}
          label={"country*"}
          placeholder={
            userData?.country?.length
              ? userData?.country
              : "Select your Country"
          }
          fieldName={"country"}
          value={userData?.country}
          onChange={(event) => handleInputChange("country", event)}
        />
        <InputField
          type={"text"}
          style={`mb-3`}
          inputStyle={`px-2 py-2`}
          label={"address*"}
          placeholder={"Enter your Address"}
          fieldName={"address"}
          value={userData?.address}
          onChange={(event) => handleInputChange("address", event)}
        />
        <div className="d-flex justify-content-between mb-3">
          <InputField
            type={"text"}
            style={`${infoStyles.half_input}`}
            inputStyle={`px-2 py-2`}
            label={"city*"}
            placeholder={"Enter your City"}
            fieldName={"city"}
            value={userData?.city}
            onChange={(event) => handleInputChange("city", event)}
          />
          <InputField
            type={"tel"}
            style={`${infoStyles.half_input}`}
            inputStyle={`px-2 py-2`}
            label={"postcode*"}
            placeholder={"Enter your Postcode"}
            fieldName={"postcode"}
            value={userData?.postcode}
            onChange={(event) => handleInputChange("postcode", event)}
          />
        </div>
        {isError && (
          <p
            className={`${infoStyles.errorText} ms-2 mb-3`}
          >
            {error}
          </p>
        )}
        <div className="d-flex align-items-center mt-4 justify-content-center justify-content-md-start">
          <p className={`${infoStyles.cancel} mb-0 ms-2`} onClick={() => navigate("/home")}>cancel</p>
          <Button
            title={"update profile"}
            style={`ms-5 px-4 py-2`}
            onClick={() => confirmUpdate()}
            loading={loading}
          />
        </div>
      </div>
      <div className="gray_box col-12 col-lg-3 flex-grow-1 order-first order-lg-last py-4 mb-3 mb-lg-0">
        <div className="fit_box mb-3 mb-md-4 d-lg-none">
          <p className="dash_title mb-3">your personal information</p>
          <div className="horizontal_line" />
        </div>
        <div className="d-flex flex-column align-items-center">
          <p className={`${infoStyles.update_title} mb-3`}>Update your photo</p>
          <p className={`${infoStyles.update_size} mb-3`}>Size 200 X 200 px</p>
          <div className={infoStyles.img_box}>
            <label htmlFor="fileInput" className={infoStyles.upload_icon}>
              <img src={uploadIcon} alt="upload icon" className="img" />
            </label>
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />
            {userData?.photo ? (
              <img
                src={userData.photo}
                alt="profile img"
                className={infoStyles.img}
                name="photo"
              />
            ) : (
              <div className={infoStyles.img}>
                <img src={avatar} alt="default profile pic" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
