import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { APIS } from "../../utils/axiosConf";

const initialState = {
  loading: false,
  allProducts: null,
  productDetails: null,
  cartData: null,
  paymentData: null,
  OrderDetails: null,
  error: null,
  localGoldPrice: null,
  walletVerifyMessage: "",
  goldTypesData: null,
  marginValue: null,
  searchOrFilterData: null,
  categories: null,
  filteringData: null,
};

// --------------- GetAllProducts -----------------
export const GetAllProducts = createAsyncThunk(
  "products/all",
  async (_, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.allProducts,
      });
      console.log("GetAllProducts", res?.data);
      return res.data;
    } catch (error) {
      console.log(
        "GetAllProducts Error: " + JSON.stringify(error?.response?.data)
      );
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- GetProductDetails -----------------
export const GetProductDetails = createAsyncThunk(
  "products/details",
  async (id, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.productDetails,
        pathParams: id,
      });
      console.log(`GetProductDetails ID:${id}`, res?.data);
      return res.data;
    } catch (error) {
      console.log(`GetProductDetails ID:${id}`, error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- AddToCart -----------------
export const AddToCart = createAsyncThunk(
  "products/cartProduct",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.addToCart,
        data: data,
        accessToken: token,
      });
      console.log(`AddToCart success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`AddToCart Error: `, error?.response?.data);
      return rejectWithValue(error?.response?.data.message);
    }
  }
);
// --------------- GetCartData -----------------
export const GetCartData = createAsyncThunk(
  "products/cart",
  async (_, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.showCart,
        accessToken: token,
      });
      console.log(`GetCartData success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`GetCartData Error: `, error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- CreatePayment -----------------
export const CreatePayment = createAsyncThunk(
  "products/payment",
  async (payData, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.makePayment,
        accessToken: token,
        params: payData,
      });
      console.log(`CreatePayment success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`CreatePayment Error: `, error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

// --------------- Add Money to Wallet -----------------
export const AddMoneyWallet = createAsyncThunk(
  "products/AddMoneyWallet",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.wallet,
        data: data,
        isFormData: true,
        accessToken: token, // Consistent with other requests
      });
      console.log("AddMoneyWallet success: ", res?.data);
      return res.data;
    } catch (error) {
      console.log("AddMoneyWallet Error: ", error?.response?.data);
      return rejectWithValue(
        error?.response?.data?.message || "Something went wrong"
      );
    }
  }
);
// --------------- RemoveProductCart -----------------
export const RemoveProductCart = createAsyncThunk(
  "products/delete",
  async (id, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "DELETE",
        path: APIS.delPet,
        accessToken: token,
        pathParams: id,
      });
      console.log(`delete product  success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`remove product Error: `, error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- GetOrderDetails -----------------
export const GetOrderDetails = createAsyncThunk(
  "products/OrderDetails",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.OrderDetails,
        accessToken: token,
        params: data
      });
      console.log(`GetOrderDetails success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`GetOrderDetails Error: `, error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- UpdateQuantity -----------------
export const UpdateQuantity = createAsyncThunk(
  "products/updateQuantity",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.productCount,
        accessToken: token,
        data: data,
      });
      console.log(`UpdateQuantity success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`UpdateQuantity Error: `, error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- purchaseWallet -----------------
export const purchaseWallet = createAsyncThunk(
  "products/purchaseWallet",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.purchaseWallet,
        accessToken: token,
        data: data,
      });
      console.log(`purchaseWallet success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`purchaseWallet Error: `, error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- Gold Price -----------------
export const getGoldPrice = createAsyncThunk(
  "products/goldPrice",
  async (_, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.goldPrice,
        accessToken: token,
      });
      console.log(`getGoldPrice success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`getGoldPrice Error: `, error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- Gold Types -----------------
export const getGoldTypes = createAsyncThunk(
  "products/getGoldTypes",
  async (_, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.goldTypes,
        accessToken: token,
      });
      console.log(`getGoldTypes success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`getGoldTypes Error: `, error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- Margin Value -----------------
export const getMarginValue = createAsyncThunk(
  "products/getMarginValue",
  async (_, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.getMargin,
        accessToken: token,
      });
      console.log(`getMarginValue success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`getMarginValue Error: `, error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- uploadUserIdImages -----------------
export const uploadUserIdImages = createAsyncThunk(
  "products/uploadUserIdImages",
  async (data, { rejectWithValue, getState }) => {
    const { token } = getState().auth;
    try {
      const res = await Axios({
        method: "POST",
        path: APIS.uploadImages,
        accessToken: token,
        data: data,
        isFormData: true
      });
      console.log(`uploadUserIdImages success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`uploadUserIdImages Error: `, error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- searchForProduct -----------------
export const searchForProduct = createAsyncThunk(
  "products/searchForProduct",
  async (searchText, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.search,
        params: { product: searchText }
      });
      console.log(`searchForProduct success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`searchForProduct Error: `, error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- filterProducts -----------------
export const filterProducts = createAsyncThunk(
  "products/filterProducts",
  async (data, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.filter,
        params: data
      });
      console.log(`filterProducts success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`filterProducts Error: `, error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- getFilterCategories -----------------
export const getFilterCategories = createAsyncThunk(
  "products/getFilterCategories",
  async (_, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.filterCategories,
      });
      console.log(`getFilterCategories success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`getFilterCategories Error: `, error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);
// --------------- getFilterData -----------------
export const getFilterData = createAsyncThunk(
  "products/getFilterData",
  async (_, { rejectWithValue }) => {
    try {
      const res = await Axios({
        method: "GET",
        path: APIS.filterData,
      });
      console.log(`getFilterData success: `, res?.data);
      return res.data;
    } catch (error) {
      console.log(`getFilterData Error: `, error?.response?.data);
      return rejectWithValue(error?.response?.data);
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // --------------- GetAllProducts-----------------
    builder.addCase(GetAllProducts.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetAllProducts.fulfilled, (state, action) => {
      state.allProducts = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(GetAllProducts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- GetProductDetails -----------------
    builder.addCase(GetProductDetails.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetProductDetails.fulfilled, (state, action) => {
      state.productDetails = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(GetProductDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- AddToCart -----------------
    builder.addCase(AddToCart.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(AddToCart.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(AddToCart.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- GetCartData -----------------
    builder.addCase(GetCartData.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetCartData.fulfilled, (state, action) => {
      state.cartData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(GetCartData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- CreatePayment -----------------
    builder.addCase(CreatePayment.pending, (state, action) => {
      state.loading = true;
      state.paymentData = null;
      state.error = null;
    });
    builder.addCase(CreatePayment.fulfilled, (state, action) => {
      state.paymentData = action.payload;
      state.cartData = null;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(CreatePayment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- Add Money Wallet  -----------------
    builder.addCase(AddMoneyWallet.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(AddMoneyWallet.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.walletVerifyMessage = action.payload.message;
    });
    builder.addCase(AddMoneyWallet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- RemoveProductCart -----------------
    builder.addCase(RemoveProductCart.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(RemoveProductCart.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(RemoveProductCart.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- GetOrderDetails -----------------
    builder.addCase(GetOrderDetails.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(GetOrderDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.OrderDetails = action.payload?.orders;
      state.error = null;
    });
    builder.addCase(GetOrderDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- UpdateQuantity -----------------
    builder.addCase(UpdateQuantity.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(UpdateQuantity.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(UpdateQuantity.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- purchase Wallet -----------------
    builder.addCase(purchaseWallet.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(purchaseWallet.fulfilled, (state, action) => {
      state.cartData = null;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(purchaseWallet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- Gold Price -----------------
    builder.addCase(getGoldPrice.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getGoldPrice.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.localGoldPrice = action.payload;
    });
    builder.addCase(getGoldPrice.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- Gold Price -----------------
    builder.addCase(getGoldTypes.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getGoldTypes.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.goldTypesData = action.payload;
    });
    builder.addCase(getGoldTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- Get Margin Value -----------------
    builder.addCase(getMarginValue.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getMarginValue.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.marginValue = action.payload;
    });
    builder.addCase(getMarginValue.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- uploadUserIdImages -----------------
    builder.addCase(uploadUserIdImages.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(uploadUserIdImages.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(uploadUserIdImages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // --------------- searchForProduct -----------------
    builder.addCase(searchForProduct.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(searchForProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.searchOrFilterData = action.payload;
    });
    builder.addCase(searchForProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.searchOrFilterData = null;
    });
    // --------------- filterProducts -----------------
    builder.addCase(filterProducts.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(filterProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.searchOrFilterData = action.payload;
    });
    builder.addCase(filterProducts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.searchOrFilterData = null;
    });
    // --------------- getFilterCategories -----------------
    builder.addCase(getFilterCategories.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.categories = null;
    });
    builder.addCase(getFilterCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.categories = action.payload;
    });
    builder.addCase(getFilterCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.categories = null;
    });
    // --------------- getFilterData -----------------
    builder.addCase(getFilterData.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.filteringData = null;
    });
    builder.addCase(getFilterData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.filteringData = action.payload;
    });
    builder.addCase(getFilterData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.filteringData = null;
    });
  },
});
export const { clearCart } = productsSlice.actions;
export default productsSlice.reducer;
