import React, { useEffect, useState } from "react";
import dashStyles from "../Dashboard/styles.module.css";
import infoStyles from "../PersonalInfo/styles.module.css";
import chPassStyles from "./styles.module.css";
import { eye, eyeSlash, imagePassword } from "../../assets";
import { Button, InputField } from "../../components";
import { changePassword } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";

function ChangePassword({ pageID, activePageID }) {
  const { changeSucessfully } = useSelector((state) => state.auth);
  const [isActive, setIsActive] = useState(false);
  const [isCurrentVisible, setIsCurrentVisible] = useState(false);
  const [isNewVisible, setIsNewVisible] = useState(false);
  const [isRePasswordVisible, setIsRePasswordVisible] = useState(false);
  const dispatch = useDispatch();
  const { token, loading } = useSelector((state) => state.auth);
  console.log(token);
  const [formData, setFormData] = useState({
    password: "",
    new_password: "",
    new_password_confirmation: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const confirmChange = () => {
    console.log(formData);
    dispatch(changePassword(formData));
  };

  useEffect(() => {
    if (activePageID === pageID) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [activePageID]);

  return (
    <div
      className={`${
        isActive ? dashStyles.active_container : dashStyles.in_active_container
      } col-11 col-md-8 flex-md-grow-1 gray_box align-self-lg-stretch`}
    >
      <div className="container-fluid py-3 pt-lg-5 pb-lg-3">
        <div className="row flex-column flex-lg-row">
          <div className="fit_box d-lg-none order-first">
            <p className="dash_title mb-3">change password</p>
            <div className="horizontal_line" />
          </div>
          <div className="col-12 col-lg-6 ps-4 pe-4 order-last order-lg-first">
            <div className="fit_box mb-5 d-none d-lg-block">
              <p className="dash_title mb-3">change password</p>
              <div className="horizontal_line" />
            </div>
            <div
              className={`${chPassStyles.inputs_box} px-3 py-4 px-lg-0 py-lg-0 pb-lg-3`}
            >
              <InputField
                type={!isCurrentVisible ? "password" : "text"}
                style={`mb-3 me-lg-4`}
                inputStyle={chPassStyles.input}
                label={"current password*"}
                placeholder={"Enter your current password"}
                rightIcon={
                  isCurrentVisible ? (
                    <img src={eye} alt="eye" className="img" />
                  ) : (
                    <img src={eyeSlash} alt="eye-slash" className="img" />
                  )
                }
                fieldName={"password"}
                value={formData.password}
                onChange={handleInputChange}
                onRightIconClick={() => setIsCurrentVisible(!isCurrentVisible)}
              />
              <InputField
                type={!isNewVisible ? "password" : "text"}
                style={`mb-3 me-lg-4`}
                inputStyle={chPassStyles.input}
                label={"set password*"}
                placeholder={"Enter your new password"}
                rightIcon={
                  isNewVisible ? (
                    <img src={eye} alt="eye" className="img" />
                  ) : (
                    <img src={eyeSlash} alt="eye-slash" className="img" />
                  )
                }
                fieldName={"new_password"}
                value={formData.new_password}
                onChange={handleInputChange}
                onRightIconClick={() => setIsNewVisible(!isNewVisible)}
              />
              <InputField
                type={!isRePasswordVisible ? "password" : "text"}
                style={`me-lg-4`}
                inputStyle={chPassStyles.input}
                label={"re-enter password*"}
                placeholder={"Enter your new password"}
                rightIcon={
                  isRePasswordVisible ? (
                    <img src={eye} alt="eye" className="img" />
                  ) : (
                    <img src={eyeSlash} alt="eye-slash" className="img" />
                  )
                }
                fieldName={"new_password_confirmation"}
                value={formData.new_password_confirmation}
                onChange={handleInputChange}
                onRightIconClick={() =>
                  setIsRePasswordVisible(!isRePasswordVisible)
                }
              />
            </div>
            <p className={chPassStyles.change_success}>{changeSucessfully}</p>
            <div className="d-flex align-items-center align-self-end mt-4 mt-lg-5 pt-lg-5 justify-content-center justify-content-lg-start">
              <p className={`${infoStyles.cancel} mb-0 ms-2`}>cancel</p>
              <Button
                title={"update password"}
                style={`ms-5 px-4 py-2`}
                loading={loading}
                onClick={() => confirmChange()}
              />
            </div>
          </div>
          <div
            className={`${chPassStyles.img_box} col-12 col-lg-6 order-lg-last`}
          >
            <img src={imagePassword} alt="password changing" className="img" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
