import React, { useEffect, useState } from "react";
import productStyles from "./styles.module.css";
import { close, minus, plus, trash } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import { GetCartData, UpdateQuantity } from "../../redux/slices/productsSlice";

function CartProduct({ product, onRemove }) {
  const [count, setCount] = useState(product?.quantity);
  const { loading } = useSelector((state) => state.products);
  const dispatch = useDispatch();

  useEffect(() => {
    const data = { product_id: product?.image[0]?.product_id, quantity: count };
    dispatch(UpdateQuantity(data))
      .unwrap()
      .then(() => {
        dispatch(GetCartData());
      });
  }, [count]);

  return (
    <>
      <div
        className={`${productStyles.product_box} d-none d-md-flex row align-items-center mb-4`}
      >
        <div className={`${productStyles.item_img_box} col-md-2`}>
          <img src={product?.image[0]?.image} alt="product" className="img" />
        </div>
        <div className="col-md-3">
          <p className={`${productStyles.product_cat} mb-2`}>gold coin</p>
          <p className={`${productStyles.product_name} mb-2`}>
            {product?.product_name}
          </p>
          <p className={productStyles.remove} onClick={onRemove}>
            remove
          </p>
        </div>
        <div
          className={`${productStyles.counter_box} d-flex justify-content-around col-md-3 pe-4`}
        >
          <div
            className={productStyles.trash_box}
            onClick={
              count > 1
                ? () => !loading && setCount(count - 1)
                : onRemove
            }
          >
            <img src={count > 1 ? minus : trash} alt="trash" className="img" />
          </div>
          <div className={productStyles.num_box}>
            <p className={productStyles.num}>{count}</p>
          </div>
          <div
            className={productStyles.plus_box}
            onClick={
              count !== product?.stock
                ? () => !loading && setCount(count + 1)
                : null
            }
          >
            <img src={plus} alt="plus" className="img" />
          </div>
        </div>

        <p className={`${productStyles.product_price} col-md-1 flex-md-grow-1`}>
          {product?.price} egp
        </p>
        <p className={`${productStyles.product_price} col-md-1 flex-md-grow-1`}>
          {product?.total_price} egp
        </p>
        <p className={`${productStyles.data_title} text-center`}>
          {count === product?.stock
            ? "There isn't more data there"
            : ""}
        </p>
      </div>

      <div
        className={`${productStyles.product_box} d-md-none row align-items-center mb-3 py-4 px-3`}
      >
        <div className={`${productStyles.item_img_box} col-5 me-3 flex-grow-1`}>
          <img src={product?.image[0]?.image} alt="product" className="img" />
        </div>
        <div className="col-6 d-flex flex-column">
          <div className="d-flex justify-content-between mb-1">
            <p className={`${productStyles.product_cat} px-1`}>gold coin</p>
            <div className={productStyles.remove_icon_box} onClick={onRemove}>
              <img src={close} alt="close" className="img" />
            </div>
          </div>
          <p className={`${productStyles.product_name}`}>
            {product?.product_name}
          </p>
          <p className={`${productStyles.price_now} mb-0`}>price now</p>
          <p className={`${productStyles.product_price}`}>
            {product?.total_price} egp
          </p>
          <div className={`${productStyles.counter_box} d-flex mt-2`}>
            <div
              className={productStyles.trash_box}
              onClick={
                count > 1
                  ? () => !loading && setCount(count - 1)
                  : null
              }
            >
              <img src={minus} alt="minus" className="img" />
            </div>
            <div className={productStyles.num_box}>
              <p className={productStyles.num}>{count}</p>
            </div>
            <div
              className={productStyles.plus_box}
              onClick={
                count !== product?.stock
                  ? () => !loading && setCount(count + 1)
                  : null
              }
            >
              <img src={plus} alt="plus" className="img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CartProduct;
