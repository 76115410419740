import React, { useRef, useState, useEffect } from "react";
import otpStyles from "./styles.module.css";
import { useDispatch } from "react-redux";
import { resendOTPNumber } from "../../redux/slices/authSlice";

const { otp_style, otp_container, otp_input } = otpStyles;

function SendOtp({ phone, error, onOtpComplete }) {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [message, setMessage] = useState("");
  const [showOtp, setShowOtp] = useState(true);
  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);
  const inputs = useRef([]);

  useEffect(() => {
    if (timer === 0) {
      setCanResend(true);
      return;
    }
    const intervalId = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timer]);

  const handleChange = (e, index) => {
    const { value } = e.target;

    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        inputs.current[index + 1].focus();
      }
    } else if (value === "") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      if (index > 0) {
        inputs.current[index - 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        inputs.current[index - 1].focus();
      }
    }
  };

  useEffect(() => {
    if (otp.every((digit) => digit !== "")) {
      const completeOtp = otp.join("");
      onOtpComplete(completeOtp); // Call the callback with the OTP
      setMessage("OTP entered successfully!"); // Set success message
      setShowOtp(false); // Hide the OTP input
    }
  }, [otp]);

  const handleResend = () => {
    if (canResend) {
      setTimer(30);
      setCanResend(false);
      dispatch(resendOTPNumber({ phone: phone }));
    }
  };

  useEffect(() => {
    if (!showOtp && error) {
      setTimeout(() => {
        setShowOtp(true)
        setOtp(Array(6).fill(""))
        setTimer(30)
      }, 1500);
    }
  }, [error, showOtp]);

  return (
    <div className={otp_style}>
      <div className={`${otp_container} pb-4`}>
        {showOtp ? (
          <div>
            <h6>We have sent a code to your phone</h6>
            <p>
              A 6-digit code has been sent to:
              <br />
              {phone}
            </p>
            <div className="otp-inputs pb-3 d-flex flex-row">
              {otp.map((_, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={otp[index]}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  ref={(el) => (inputs.current[index] = el)}
                  className={otp_input}
                />
              ))}
            </div>
            <span
              onClick={handleResend}
              style={{ cursor: canResend ? "pointer" : "not-allowed" }}
            >
              Resend Code
            </span>{" "}
            ({timer}s)
          </div>
        ) : (
          !error
            ? <span>{message}</span>
            : <span>Invalid verification code , Please Try Again</span>
        )}
      </div>

    </div>
  );
}

export default SendOtp;