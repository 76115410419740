import React, { useState } from "react";
import productStyles from "./styles.module.css";
import { cartProduct, eyeProduct, favoriteProduct } from "../../assets";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";

function ProductItem({ item, style, onSelect }) {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);
  const { loading } = useSelector((state) => state.products);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);
  const sweetalertHandler = (message) => {
    Swal.fire({
      title: message,
      customClass: {
        popup: "my_popup", // Apply the custom class to the popup container
        title: "my_title", // Apply the custom class to the title
        confirmButton: "my_content", // Apply the custom class to the content
      },
      showClass: {
        popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
            `,
      },
      hideClass: {
        popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
            `,
      },
    });
  };
  const handleClick = () => {
    if (item.stock !== 0) {
      onSelect ? onSelect() : navigate("/productDetails", { state: { item } });
    } else {
      sweetalertHandler("Sorry the Product is out of Stock");
    }
  };

  const isMobile = useMediaQuery({ maxWidth: "767px" });
  const isTablet = useMediaQuery({ maxWidth: "991px" });

  return (
    <div
      className={`${productStyles.productBox} ${style}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {isHovering && !isMobile && (
        <div className={productStyles.product_icons}>
          <div className={productStyles.product_icon}>
            <img src={eyeProduct} alt="product-option" />
          </div>
          <div className={productStyles.product_icon}>
            <img src={favoriteProduct} alt="product-option" />
          </div>
          <div className={productStyles.product_icon}>
            <img src={cartProduct} alt="product-option" />
          </div>
        </div>
      )}
      {isTablet && !isMobile && (
        <div className={productStyles.product_icons}>
          <div className={productStyles.product_icon}>
            <img src={eyeProduct} alt="product-option" />
          </div>
          <div className={productStyles.product_icon}>
            <img src={favoriteProduct} alt="product-option" />
          </div>
          <div className={productStyles.product_icon}>
            <img src={cartProduct} alt="product-option" />
          </div>
        </div>
      )}
      <div className={productStyles.product_img_box}>
        <img src={item?.image} alt="trejars" className="img" />
      </div>
      <p className={productStyles.product_title}>{item?.name}</p>
      <p className={productStyles.product_now}>price now</p>
      <>
        {loading ? (
          <ThreeDots
            visible={true}
            height="50"
            width="50"
            color="#A7833F"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{marginBottom: 34}}
            wrapperClass=""
          />
        ) : (
          <p className={`mb-5 ${productStyles.product_price}`}>
            {`${Math.floor(
              Number(String(item?.priceAfter).replace(/,/g, ""))
            )} egp`}
          </p>
        )}
      </>
      <div className={`${productStyles.out_of_stock}`}>
        {item.stock === 0
          ? <p className={productStyles.product_price}>Out of Stock</p>
          : ""}
      </div>
      {isMobile && (
        <div className="d-flex justify-content-between w-75 px-2">
          <div className={productStyles.product_icon}>
            <img src={eyeProduct} alt="product-option" />
          </div>
          <div className={productStyles.product_icon}>
            <img src={favoriteProduct} alt="product-option" />
          </div>
          <div className={productStyles.product_icon}>
            <img src={cartProduct} alt="product-option" />
          </div>
        </div>
      )}
      <Button
        title={"buy now"}
        style={`${productStyles.btn}  d-none d-md-block`}
        onClick={handleClick}
      />
    </div>
  );
}

export default ProductItem;
