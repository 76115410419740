import { combineReducers } from '@reduxjs/toolkit';
import productsSlice from './slices/productsSlice';
import authSlice from './slices/authSlice';
import contact from './slices/contantUsSlice';

const rootReducer = combineReducers({
  products: productsSlice,
  auth: authSlice,
  contact
});

export default rootReducer;
