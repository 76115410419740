import React from "react";
import likeStyles from './styles.module.css'
import { arrowRight } from "../../assets";
import { useNavigate } from "react-router-dom";

function MayLikeItem({ item }) {
  const navigate = useNavigate();

  return (
    <div className={`${likeStyles.item_container}`}
      onClick={() => {
        navigate("/shop")
        window.scrollTo(0, 0);
      }}>
      <div className={`${likeStyles.img_box}`}>
        <img src={item.url} alt="trejars" className="img cover" />
      </div>
      <p className={`${likeStyles.title} my-2 px-1 d-none d-lg-block`}>
        {item.title}
      </p>
      <div className="d-flex justify-content-between align-items-baseline px-1 pe-2 d-none d-lg-flex">
        <p className={likeStyles.explore}>explore now!</p>
        <div className={likeStyles.arrow_box}>
          <img src={arrowRight} alt="right arrow" className="img" />
        </div>
      </div>
    </div >
  );
}

export default MayLikeItem;
